<template>
	<div class="app" :class="{ 'no-scroll': isShowRoomPictureOpen, 'is-inpwa': $isPwa() }">
		<AppHeader fixed :class="{ 'is-inpwa': $isPwa() }" :key="`${keyVersion}1`">
			<b-row class="ml-0">
				<BackInHistoryBtn v-if="$isPwa()" :key="`${keyVersion}2`" class="mr-3" />
				<SidebarToggler ref="side-bar" class="d-lg-none" display="sm" mobile :key="`${keyVersion}3`" />
				<button
					v-if="$screen.width <= 567"
					type="button"
					class="btn mx-0 btn-outline-primary btn-sm"
					style="width: 32px; height: 26px; margin-top: 3px"
					@click="backToMyDashboard"
				>
					<home :size="16" />
				</button>
				<a class="navbar-brand" style="cursor: pointer">
					<img
						class="navbar-brand-full"
						src="img/brand/thegreenshot-brand.png"
						@onclick="document.location.href = `#/my-dashboard${version}`"
						width="100"
						height="30"
						alt="TheGreenShot"
					/>
					<img class="navbar-brand-minimized" src="img/brand/tgs-icon.svg" width="30" height="30" alt="TGS" />
				</a>
				<SidebarToggler ref="side-bar" class="d-md-down-none" display="sm" :key="`${keyVersion}4`" />
				<b-navbar-nav class="d-md-down-none">
					<HeaderProjectToggler :key="`${keyVersion}5`" />
					<button
						type="button"
						class="btn mx-0 btn-outline-primary btn-sm ml-2"
						style="display: flex; align-items: center; justify-content: center; width: 32px; height: 26px"
						@click="backToMyDashboard"
					>
						<home :size="16" />
					</button>
					<button
						v-if="!isFilmSingle && !isFilmSingleFree && statusNotifications && isProduction"
						v-b-tooltip.hover.bottom.html="`${FormMSG(301, 'Add new project')}`"
						type="button"
						class="btn mx-0 btn-outline-primary btn-sm ml-2"
						style="display: flex; align-items: center; justify-content: center; width: 32px; height: 26px"
						@click="handleActionModal"
					>
						<Plus :size="16" />
					</button>
				</b-navbar-nav>
			</b-row>
			<b-row class="mr-0">
				<b-navbar-nav class="">
					<HeaderLanguageToggler :style="`${$screen.width < 992 ? 'margin-right: 2px; ' : ''}`" :key="`${keyVersion}6`" class="mr-2" />
					<b-nav-item>
						<b-dropdown
							size="sm"
							id="dropdown-header-help"
							:text="menuLabel()"
							class="dropdown--large"
							v-if="$screen.width >= 992"
							variant="outline-primary"
							right
						>
							<b-dropdown-item @click="openDocumentation()">
								{{ FormMSG(19946, 'Online guide') }}
							</b-dropdown-item>
							<b-dropdown-item @click="downloadStartUpGuide()">
								{{ FormMSG(19947, 'Quick Startup PDF Guide') }}
							</b-dropdown-item>
							<b-dropdown-item v-if="isFilmSingle" @click="showWizard">
								{{ FormMSG(19948, 'View wizard') }}
							</b-dropdown-item>
						</b-dropdown>
						<b-dropdown
							size="sm"
							id="dropdown-header-help"
							variant="outline-primary"
							toggle-class="text-decoration-none"
							class="dropdown--large"
							style="width: 32px"
							no-caret
							v-else
						>
							<template #button-content>
								<help-circle :size="16" />
							</template>
							<b-dropdown-item @click="openDocumentation()">
								{{ FormMSG(19946, 'Online guide') }}
							</b-dropdown-item>
							<b-dropdown-item @click="downloadStartUpGuide()">
								{{ FormMSG(19947, 'Quick Startup PDF Guide') }}
							</b-dropdown-item>
							<b-dropdown-item v-if="isFilmSingle" @click="showWizard">
								{{ FormMSG(19948, 'View wizard') }}
							</b-dropdown-item>
						</b-dropdown>
					</b-nav-item>
					<div v-if="!statusNotifications">
						<HeaderNotifications :key="`${keyVersion}7`" />
					</div>
					<b-nav-item class="ml-2">
						<DefaultHeaderDropdownAccnt ref="dropdown-accnt" :key="`${keyVersion}8`" />
					</b-nav-item>
				</b-navbar-nav>
			</b-row>
			<!-- <AsideToggler class="d-none d-lg-block" /> -->
			<!--<AsideToggler class="d-lg-none" mobile />-->
		</AppHeader>
		<div
			class="app-body"
			id="app-body"
			:class="{
				'is-pwa': $isPwa(),
				'is-ios': $isIOSDevice(),
				'is-android': $isAndroidDevice()
			}"
		>
			<AppSidebar dropdown-mode="close" fixed :key="`${keyVersion}9`">
				<SidebarHeader :key="`${keyVersion}10`" />
				<SidebarForm :key="`${keyVersion}11`" />
				<SidebarNav :nav-items="navItems" class="bg-secondary" :key="`${keyVersion}12`" />
				<SidebarFooter :key="`${keyVersion}13`" />
				<SidebarMinimizer :key="`${keyVersion}14`" />
			</AppSidebar>
			<main class="main">
				<Breadcrumb v-if="$screen.width < 576" :list="list" />
				<div class="container-fluid pt-3" :key="`${keyVersion}18`">
					<router-view />
				</div>
			</main>
			<AppAside fixed :key="`${keyVersion}15`">
				<!--aside-->
				<DefaultAside :key="`${keyVersion}16`" />
			</AppAside>
		</div>
		<TheFooter :key="`${keyVersion}17`">
			<!--footer-->
			<div class="ml-auto">
				<span class="ml-1">&copy; {{ copyright }} </span>
				<a href="https://www.thegreenshot.green" target="_blank" rel="noopener noreferrer">TheGreenShot</a>
			</div>
		</TheFooter>
		<tip-modal-component :key="`${keyVersion}18`" />
		<help-modal-component :key="`${keyVersion}19`" />
		<PreviewShowRoomImage :key="`${keyVersion}20`" />
		<modal-wizard :key="`${keyVersion}21`" />
		<b-modal size="xxl" v-model="open" no-close-on-backdrop :title="`${FormMSG(301, 'Add new project')}`" dialog-class="dialog-custom font-weight-bold">
			<Fimalac />
			<template #modal-footer>
				<div class="d-flex flex-row justify-content-end w-100 gap-1 w-1">
					<div>
						<b-button variant="outline-primary" size="sm" class="border-1" @click="handleActionModal"> {{ FormMSG(302, 'Close') }} </b-button>
					</div>
				</div>
			</template>
		</b-modal>
	</div>
</template>

<script>
import nav from '@/_nav';
import {
	Header as AppHeader,
	SidebarToggler,
	Sidebar as AppSidebar,
	SidebarFooter,
	SidebarForm,
	SidebarHeader,
	SidebarMinimizer,
	Aside as AppAside,
	Footer as TheFooter,
	Breadcrumb
} from '@coreui/vue';
import { HttpValidation } from '@/helpers/HttpValidation';
import SidebarNav from '@/components/Forks/SidebarNav';
import DefaultAside from './DefaultAside';
import DefaultHeaderDropdownAccnt from './DefaultHeaderDropdownAccnt';
import HeaderProjectToggler from '../components/HeaderProjectToggler';
import HeaderLanguageToggler from '../components/HeaderLanguageToggler';
import HeaderNotifications from '../components/HeaderNotifications';
import TipModalComponent from '../components/Modals/TipModal';
import ModalWizard from '../components/Modals/ModalWizard';
import HelpModalComponent from '../components/Modals/HelpModal';
import BackInHistoryBtn from '@/components/BackInHistoryBtn';
import PreviewShowRoomImage from '@/components/Packages/FIlesPreviewer/components/MainModalContainer';
import languageMessages from '@/mixins/languageMessages';
import { getParamVersion } from '@/shared/version';
import moment from 'moment';
import { Wand, HelpCircle, Home, Plus } from 'lucide-vue';
import { store } from '@/store';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import globalMixin from '@/mixins/global.mixin';
import _ from 'lodash';
import { getValidations } from '@/cruds/validations.crud';
import { GetPlanningValidation } from '@/cruds/users.crud';
import Fimalac from '@/views/projects/FimalacProject';

async function isShowFimalacPlanning() {
	const userId = store.userID();
	let data = await getValidations(4);
	let result = data !== undefined ? data.find((item) => +item.user.id === +userId) : undefined;
	if (result !== undefined) {
		const type_status = +result.validationLevelTs;
		if (type_status !== null && (type_status === 0 || type_status === 1 || type_status === 2)) {
			return HttpValidation.setValidation(true);
		} else {
			return HttpValidation.setValidation(false);
		}
	} else {
		return HttpValidation.setValidation(false);
	}
}

function isFeatureAllowed(feature) {
	const isFeatureRestricted =
		(feature.name === 'Budget' && !store.state.myProject.useBudget) ||
		(feature.name === 'My budget' && !store.state.myProject.useBudget) ||
		(feature.name === 'Tasks' && !store.state.myProject.useTask) ||
		(feature.name === 'Task types' && !store.state.myProject.useTask) ||
		(feature.name === 'My Tasks' && !store.state.myProject.useTask) ||
		(feature.name === 'My Planning' && !store.state.myProject.useTask) ||
		(feature.name === 'Planning' && !store.state.myProject.useTask) ||
		(feature.name === 'Suppliers' && !store.state.myProject.useGreenSupplier) ||
		(feature.name === 'Carbon template' && !store.state.myProject.useCarbonTemplate) ||
		(feature.name === 'Carbon' && !store.state.myProject.useGreenTable) ||
		(feature.name === 'Reporting' && !store.state.myProject.useReporting) ||
		(feature.name === 'Carbon removal' && !store.state.myProject.useCarbonRemoval) ||
		(feature.name === 'Waste' && !store.state.myProject.useWaste) ||
		(feature.name === 'Green suppliers' && !store.state.myProject.useGreenSupplier) ||
		(feature.name === 'Contacts' && !store.state.myProject.useContacts) ||
		(feature.name === 'Timesheets planning' && !store.state.myProject.useTimesheetPlanning) ||
		(feature.name === 'Tip of the day' && !store.state.myProject.useTipOfDay) ||
		(feature.name === 'Validations' &&
			!(
				store.state.myProject.useExpense ||
				store.state.myProject.usePurchaseOrder ||
				store.state.myProject.useTimesheet ||
				store.state.myProject.useTimesheetPlanning
			)) ||
		(feature.name === 'My tip of the day' && !store.state.myProject.useTipOfDay) ||
		(feature.name === 'Crew Setup' &&
			((!store.canManageCrewList() &&
				!store.canManageDocumentPackage() &&
				!(
					store.state.myProfile.upm ||
					store.state.myProfile.gateKeeper ||
					store.state.myProfile.productionAccountant ||
					store.state.myProfile.lineProducer ||
					store.state.myProfile.studio ||
					store.state.myProfile.canValidateI9
				)) ||
				!store.state.myProject.useCrewSetup)) ||
		(feature.name === 'Documents' &&
			((!store.canManageDocuments() && !store.canManageCallSheets()) || (!store.state.myProject.useDocument && !store.state.myProject.useCallSheet))) ||
		(feature.name === 'Locations' && (!store.canManageLocations() || !store.state.myProject.useLocations)) ||
		(feature.name === 'Waste' && !store.canViewWasteScreen()) ||
		(feature.hasOwnProperty('notForMobile') && window.innerWidth <= 576) ||
		(feature.name === 'Carbon' && !store.canViewGreenTable()) ||
		(feature.name === 'Carbon template' && !store.canViewGreenTable()) ||
		(feature.name === 'Imports' && (!store.canExportImport() || !store.state.myProject.useImport)) ||
		(feature.name === 'Exports' && (!store.canExportImport() || !store.state.myProject.useExport)) ||
		(feature.name === 'Reporting' && !store.canAccessGreenReporting()) ||
		(feature.name === 'Carbon removal' && !store.canManageCarbonRemoval()) ||
		(feature.name === 'Configuration' && !store.canAccessConfig()) ||
		(feature.name === 'Budget GL' && ((!store.canViewGlobalBudget() && !store.canViewDepartmentBudget()) || !store.state.myProject.useBudget)) ||
		(feature.name === 'Transports' && !store.state.myProject.useTransport) ||
		(feature.name === 'My Transports' && !store.state.myProject.useTransport) ||
		(feature.name === 'Accomodations' && !store.state.myProject.useAccommodation) ||
		(feature.name === 'My Accomodations' && !store.state.myProject.useAccommodation) ||
		(feature.name === 'My Work' && !store.state.myProject.useTimesheet) ||
		(feature.name === 'My Timesheets' && !store.state.myProject.useTimesheet) ||
		(feature.name === 'Validate Work' && !store.state.myProject.useTimesheet) ||
		(feature.name === 'My Expenses' && !store.state.myProject.useExpense) ||
		(feature.name === 'Validate Expenses' && !store.state.myProject.useExpense) ||
		(feature.name === 'My PO' && !store.state.myProject.usePurchaseOrder) ||
		(feature.name === 'Validate P-Os' && !store.state.myProject.usePurchaseOrder) ||
		(feature.name === 'Entry for Crew' && !store.state.myProject.useEntryForCrew) ||
		(feature.name === 'Finance' && !HttpValidation.status) ||
		(feature.name === 'Timesheets' && !store.canAccessTimesheetEntryForCrew() && !store.state.myProject.useEntryForCrewTimesheet) ||
		(feature.name === 'Expenses' && !store.canAccessExpenseEntryForCrew() && !store.state.myProject.useEntryForCrewExpenses) ||
		(feature.name === 'PO' && !store.canAccessPoEntryForCrew() && !store.state.myProject.useEntryForCrewPo) ||
		(feature.hasOwnProperty('notForFilm') && store.isFilm()) ||
		(feature.hasOwnProperty('haveActiveReporting') && (!store.greenReporting() == 1 || !store.greenReporting() == 2 || !store.greenReporting() == 3)) ||
		(feature.hasOwnProperty('filmOnly') && !store.isFilm() && !store.isFilmSingleUser() && !store.isFilmSingleUserFree()) ||
		(feature.hasOwnProperty('pmeOnly') && !store.isPme()) ||
		(feature.hasOwnProperty('prodOnly') && !store.state.myProject.useProduction) ||
		(feature.hasOwnProperty('useFinance') && !store.state.myProject.useFinance) ||
		(feature.hasOwnProperty('useGreen') && !store.state.myProject.useGreen) ||
		(feature.hasOwnProperty('greenReporting') && store.greenReporting() !== feature.greenReporting) ||
		(feature.hasOwnProperty('translatorOnly') && !store.isTranslator()) ||
		(feature.hasOwnProperty('notForSingleUser') && (store.isFilmSingleUser() || store.isFilmSingleUserFree())) ||
		(feature.hasOwnProperty('devOnly') && !store.isDev()) ||
		(feature.hasOwnProperty('canAccessMyBudgetOnly') && !store.canAccessMyBudget()) ||
		(feature.hasOwnProperty('isCarbonClap') && !store.isCarbonClapReporting()) ||
		(feature.hasOwnProperty('canAccessGreenReporting') && !store.canAccessGreenReporting()) ||
		(feature.hasOwnProperty('notForSingleProject') && (store.isFilmSingleUserFree() || store.isFilmSingleUser()));
	return !isFeatureRestricted;
}

function removeNotAllowedChildren(menu) {
	if (menu.savedChildren.length === 0) {
		for (let i = 0; i < menu.children.length; i++) {
			menu.savedChildren.push(menu.children[i]);
		}
		//console.log("in ISNILI removeNotAllowedChildren SAVED CHILDREN AFTER:",menu.savedChildren);
	}
	// filling newChildren with authorized children
	menu.children.splice(0, menu.children.length); // empty children array
	for (let i = 0; i < menu.savedChildren.length; i++) {
		// console.log(menu.savedChildren[i],isFeatureAllowed(menu.savedChildren[i]) )
		if (isFeatureAllowed(menu.savedChildren[i])) {
			menu.children.push(menu.savedChildren[i]);
		}
	}
	//console.log("in removeNotAllowedChildren NEWCHILDREN:",menu.children);
}
// console.log("in default container")
function removeItemsWithNoChildren(arr) {
	const filteredArray = arr.filter((item) => item.children.length > 0);
	return filteredArray;
}

export default {
	name: 'DefaultContainer',
	components: {
		// AsideToggler,
		AppHeader,
		AppSidebar,
		AppAside,
		TheFooter,
		Breadcrumb,
		DefaultAside,
		DefaultHeaderDropdownAccnt,
		SidebarForm,
		BackInHistoryBtn,
		SidebarFooter,
		SidebarToggler,
		SidebarHeader,
		SidebarNav,
		SidebarMinimizer,
		HeaderProjectToggler,
		HeaderLanguageToggler,
		HeaderNotifications,
		TipModalComponent,
		HelpModalComponent,
		PreviewShowRoomImage,
		Wand,
		HelpCircle,
		Home,
		Plus,
		ModalWizard,
		Fimalac
	},
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	data: function () {
		return {
			// Initialize your apollo data
			shared: store.state,
			tipOfTheDay: {},
			version: getParamVersion(),
			project: null,
			screenWidth: window.innerWidth,
			navItems: [],
			open: false,
			statusNotifications: false,
			validationPlanningType: false
			// navItems: [],
		};
	},
	async created() {
		// Add an event listener to update the screen width when the window is resized
		await this.initialize();
		window.addEventListener('resize', this.updateScreenWidth);
	},
	beforeDestroy() {
		// Remove the event listener to prevent memory leaks
		window.removeEventListener('resize', this.updateScreenWidth);
	},
	watch: {
		shared: {
			async handler(newVal) {
				await this.getNavItems();
				return newVal;
			},
			immediate: true,
			deep: true
		},
		tipOfTheDay: {
			handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		},
		$route: {
			handler(to, from) {},
			immediate: true
		},
		screenWidth: {
			async handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},
	computed: {
		isProduction() {
			return this.validationPlanningType;
		},
		name() {
			return this.$route.name;
		},
		list() {
			return this.$route.matched.filter((route) => route.name || route.meta.label);
		},

		/**
		 * @return {Boolean}
		 */
		isShowRoomPictureOpen() {
			return this.$store.getters['app/isShowRoomPictureOpen'];
		},
		copyright() {
			return moment().format('YYYY');
		},
		keyVersion() {
			const version = getParamVersion();

			return version.replace('?v=', '');
		}
	},
	methods: {
		async initialize() {
			const init = await store.onLoad().then(async (res) => {
				if (res) {
					await this.getNavItems();
					this.useFimalac();
					await this.getValidationPlanning();
					return res;
				}
			});
			return init;
		},
		async getValidationPlanning() {
			const userId = store.userID();
			const result = await GetPlanningValidation(userId);
			if (result) {
				let type_status = result.validationLevelTs;
				this.validationPlanningType = !!type_status;
				return this.validationPlanningType;
			}
			return this.validationPlanningType;
		},
		useFimalac() {
			const license = store.state.myProject.license !== undefined ? store.state.myProject.license.license : '';
			const isFimalacLicense = /fimalac/gi.test(license);
			const status = HttpValidation.status && isFimalacLicense;
			this.statusNotifications = status;
		},
		handleActionModal() {
			this.open = !this.open;
		},
		async getNavItems() {
			await isShowFimalacPlanning();
			const screenWidth = this.screenWidth;
			// removing not allowed children from menus
			if (screenWidth !== 0) {
				nav.forEach(removeNotAllowedChildren);
				const selectedMenuOptions = removeItemsWithNoChildren(nav.filter(isFeatureAllowed));
				//get current my project
				const noTranslationMenus = store.state.noTranslationMenus;
				// todo remove i, et ajouter id dans nav
				const translateMenuName = (items) => {
					return items.map((item) => {
						const id = this.getMsgIdForProjectType(item);
						let message = '';
						if (item.hasOwnProperty('titleUpdatable') && item.titleUpdatable === true) {
							message = this.getCostumTitle(id, {
								userName: noTranslationMenus.userName,
								hodName: noTranslationMenus.hodName,
								managerName: noTranslationMenus.managerName
							});

							if (message === null) {
								message = this.FormMSG(id, this.getMsgNameForProjectType(item));
							}
						} else {
							message = this.FormMSG(id, this.getMsgNameForProjectType(item));
						}

						let menu = {
							...item,
							name: message
						};

						if (item.hasOwnProperty('children') && item.children.length > 0) {
							menu.children = translateMenuName(menu.children);
						}

						return menu;
					});
				};

				const selectedMenuOptionsTranslated = translateMenuName(selectedMenuOptions);
				//console.log("test selectedMenuOptionsTranslated:",selectedMenuOptionsTranslated);
				this.navItems = selectedMenuOptionsTranslated;
				// return selectedMenuOptionsTranslated;
			}
		},
		updateScreenWidth() {
			this.screenWidth = window.innerWidth; // Update screen width when called
		},
		showWizard() {
			this.$bvModal.show('wizard-modal');
		},
		backToMyDashboard() {
			return this.$router.push({ name: 'My Dashboard' });
		},
		menuLabel() {
			return this.FormMSG(19998, 'Help');
		},
		getMsgIdForProjectType(feature) {
			//  console.log("in getMsgIdForProjectType feature:",feature);
			if (store.isPme()) {
				if (feature.hasOwnProperty('msgIdPme')) {
					//console.log("in getMsgIdForProjectType FOUND msgIdPme:",feature);
					return feature.msgIdPme;
				}
			}
			return feature.msgId;
		},
		getMsgNameForProjectType(feature) {
			if (store.isPme()) {
				if (feature.hasOwnProperty('namePme')) {
					//console.log("in getMsgIdForProjectType FOUND namePME:",feature);
					return feature.namePme;
				}
			}
			return feature.name;
		},
		getCostumTitle(id, { userName, hodName, managerName }) {
			let title = null;
			if ((id === 71 || id === 171) && userName !== '') {
				title = userName;
			}

			if ((id === 11 || id === 111) && hodName !== '') {
				title = hodName;
			}

			if ((id === 18 || id === 118) && managerName !== '') {
				title = managerName;
			}

			return title;
		},
		downloadStartUpGuide() {
			let currLang = store.appLanguage();
			//     console.log("currLang=",currLang);
			//     console.log("myProject=",store.state.myProject);
			let userLevel = store.isProd() ? 2 : store.isAdmin() ? 1 : 0;
			let projectType = store.state.myProject.projectType;
			var urlStr = process.env.VUE_APP_GQL + '/getQuickStartUpGuide/' + currLang + '/' + projectType + '/' + userLevel;

			if ((store.isFilmSingleUserFree() || store.isFilmSingleUser()) && currLang === 1) {
				urlStr = 'https://docs.thegreenshot.green/fr-quick-solo-film/';
			} else if ((store.isFilmSingleUserFree() || store.isFilmSingleUser()) && currLang !== 1) {
				urlStr = 'https://docs.thegreenshot.green/en-quick-start-solo-film/';
			}
			window.open(urlStr); // in a new window
		},
		openDocumentation() {
			this.$bvModal.show('help-documentation-modal');
		}
	}
};
</script>

<style>
#dropdown-header-help .dropdown-menu.dropdown-menu-right.show {
	min-width: 240px;
}
.app.no-scroll {
	overflow: hidden !important;
	height: 100vh !important;
}
.dialog-custom {
	max-width: 90% !important;
}
</style>
