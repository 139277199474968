<template>
	<router-link ref="dropdowncoruiItem" tag="li" class="nav-item nav-dropdown" :to="url" disabled>
		<div class="nav-link nav-dropdown-toggle" @click="handleClick"><i :class="classIcon"></i> {{ name }}</div>
		<ul class="nav-dropdown-items">
			<slot></slot>
		</ul>
	</router-link>
</template>

<script>
import { SidebarNavDropdown } from '@coreui/vue';

export default {
	extends: SidebarNavDropdown,
	props: {
		open: {
			type: Boolean,
			required: false,
			default: true
		},
		item: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	watch: {
		open(status) {
			const action = status ? 'add' : 'remove';
			this.$refs.dropdowncoruiItem.$el.classList[action]('open');
		}
	},
	mounted() {
		this.initDefaultOpenStat();
	},
	methods: {
		initDefaultOpenStat() {
			if (!this.open) {
				this.$refs.dropdowncoruiItem.$el.classList.remove('open');
			}
		},

		handleClick(e) {
			this.$emit('drop-state-change', this.item);
			e.preventDefault();
			e.target.parentElement.classList.toggle('open');
		}
	}
};
</script>
