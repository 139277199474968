<template>
	<div class="language-toggler dropdown" v-if="optionsForLanguage.length > 0">
		<b-dropdown variant="outline-secondary" size="sm" :text="curArrayItem.text" v-if="$screen.width >= 992">
			<b-dropdown-header id="dropdown-header-label">
				{{ FormMSG(10, 'Switch to another language') }}
			</b-dropdown-header>
			<b-dropdown-item-button v-for="lang in langArray" :key="lang.value" action @click="changeLanguage(lang)">
				{{ lang.text }}
			</b-dropdown-item-button>
		</b-dropdown>

		<b-dropdown size="sm" variant="link" toggle-class="text-decoration-none" v-if="$screen.width < 992" no-caret>
			<template #button-content>
				<b-button variant="outline-primary" size="sm" class="mx-0" style="width: 32px" :disabled="isLangueChangeLoading">
					<b-spinner v-if="isLangueChangeLoading" small />
					<globe-2 v-else :size="16" />
				</b-button>
			</template>
			<b-dropdown-header id="dropdown-header-label">
				{{ FormMSG(10, 'Switch to another language') }}
			</b-dropdown-header>
			<b-dropdown-item v-for="lang in langArray" :key="lang.value" action :active="language === lang.value" @click="changeLanguage(lang)">
				{{ lang.text }}
			</b-dropdown-item>
		</b-dropdown>
	</div>
</template>

<script>
import gql from 'graphql-tag';
import { store } from '@/store';
import languageMessages from '@/mixins/languageMessages';
import { Globe2 } from 'lucide-vue';
import globalMixin from '@/mixins/global.mixin';

// Variable doit être dans la query : https://github.com/graphql/graphiql/issues/156
const mutation_changeLanguage = gql`
	mutation ($LanguageId: Int!) {
		UpdAppLanguage(LanguageId: $LanguageId)
	}
`;

export default {
	name: 'HeaderLanguageToggler',
	components: { Globe2 },
	mixins: [languageMessages, globalMixin],
	data: function () {
		return {
			// Initialize your apollo data
			erreur: {},
			myerr: '',
			shared: store.state,
			language: 0,
			curArrayItem: { value: 0, text: 'English' },
			langArray: [{ value: 0, text: 'English' }],
			isLangueChangeLoading: false
		};
	},
	computed: {
		getCurLanguageToDisplay() {
			return curArrayItem.text;
		},
		optionsForLanguage() {
			//console.log("in optionsForLanguage, appLanguage:",store.appLanguage())
			const isProdEnv = process.env.VUE_APP_GQL.includes('svc'); // meaning in production only allow validated language choice:   const isProdEnv = process.env.VUE_APP_GQL.includes("svc");
			//console.log("PRODUCTION_ENVIRONMENT :", isProdEnv);
			this.language = store.appLanguage();
			if (process.env.VUE_APP_RUNNING_MODE === 'PROD' && !store.isTranslator()) {
				this.langArray = this.FormMenu(10001);
			} else if ((process.env.VUE_APP_RUNNING_MODE === 'PROD' && store.isTranslator()) || process.env.VUE_APP_RUNNING_MODE !== 'PROD') {
				this.langArray = this.FormMenu(1);
			}

			for (let i = 0; i < this.langArray.length; i++) {
				if (this.langArray[i].value == this.language) {
					this.curArrayItem = this.langArray[i];
					//console.log("in optionsForLanguage, curArrayItem:",this.curArrayItem)
					break;
				}
			}
			return this.langArray;
		}
	},
	methods: {
		async changeLanguage(newChoice) {
			if (this.$isPwa() && this.$isIOSDevice()) {
				const action = async () => {
					this.actionForChangeLanguagePWA(newChoice);
				};
				this.confirmModal(action, this.FormMSG(11, 'The app will disconnect to support changing your language. Want to continue ?'));
			} else {
				await this.actionForChangeLanguageWeb(newChoice);
			}
		},

		async actionForChangeLanguageWeb(newChoice) {
			// console.log("new choice:", newChoice);
			this.isLangueChangeLoading = true;

			for (let i = 0; i < this.langArray.length; i++) {
				if (this.langArray[i].value == newChoice.value) {
					this.curArrayItem = this.langArray[i];

					this.language = this.langArray[i].value;
					break;
				}
			}

			const LanguageId = parseInt(this.language, 10);

			await this.$apollo
				.mutate({
					mutation: mutation_changeLanguage,
					variables: {
						LanguageId
					}
				})
				.then(async (result) => {
					// console.log("new token:", result.data.UpdAppLanguage);
					store.state.myProfile.appLanguage = parseInt(this.language, 10);
					await store.onNewToken(this.$apollo.provider.defaultClient, result.data.UpdAppLanguage); // executer onNewToken avant de storer le nouveau projet, sinon supprimé
					//windows.history.go()
					console.log({ 'this.$isPwa()': this.$isPwa() });

					this.myerr = '';

					this.$router.go();
				})
				.catch((error) => {
					this.erreur = error;
				});

			this.isLangueChangeLoading = false;
		},

		async actionForChangeLanguagePWA(newChoice) {
			// console.log("new choice:", newChoice);
			this.isLangueChangeLoading = true;

			for (let i = 0; i < this.langArray.length; i++) {
				if (this.langArray[i].value == newChoice.value) {
					this.curArrayItem = this.langArray[i];
					this.language = this.langArray[i].value;
					break;
				}
			}

			const LanguageId = parseInt(this.language, 10);

			await this.$apollo
				.mutate({
					mutation: mutation_changeLanguage,
					variables: {
						LanguageId
					}
				})
				.then(async (result) => {
					// console.log("new token:", result.data.UpdAppLanguage);
					store.state.myProfile.appLanguage = parseInt(this.language, 10);
					await store.onNewToken(this.$apollo.provider.defaultClient, result.data.UpdAppLanguage); // executer onNewToken avant de storer le nouveau projet, sinon supprimé
					//windows.history.go()
					console.log({ 'this.$isPwa()': this.$isPwa() });

					this.myerr = '';

					store.onLogout();
					document.location.href = '/';
				})
				.catch((error) => {
					this.erreur = error;
				});

			this.isLangueChangeLoading = false;
		}
	}
};
</script>

<style>
.language-toggler .dropdown-menu {
	/* margin: 20px; */
	padding: 0;
}
</style>
