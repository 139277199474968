var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "AppHeaderDropdown",
        { attrs: { right: "", "no-caret": "" } },
        [
          _c("template", { slot: "header" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "lazy-container",
                    rawName: "v-lazy-container",
                    value: { selector: "img" },
                    expression: "{ selector: 'img' }",
                  },
                ],
              },
              [
                _vm.$has(_vm.profile)
                  ? _c(
                      "span",
                      { staticClass: "auth-name not-on-screen-mobile px-1" },
                      [_vm._v(_vm._s(_vm.profile.firstName))]
                    )
                  : _vm._e(),
                _c("img", {
                  staticClass: "img-avatar",
                  staticStyle: {
                    width: "30px",
                    height: "30px",
                    "border-radius": "15px",
                    border: "1px solid #aaa",
                    padding: "1px",
                  },
                  attrs: {
                    "data-src": this.imageName,
                    "data-error": "img/brand/avatar.jpg",
                    "data-loading": "img/brand/tenor.gif",
                    alt: "pict",
                  },
                }),
              ]
            ),
          ]),
          _c(
            "template",
            { slot: "dropdown" },
            [
              _c(
                "b-dropdown-header",
                { staticClass: "text-center", attrs: { tag: "div" } },
                [_c("strong", [_vm._v(_vm._s(_vm.FormMSG(15, "Account")))])]
              ),
              _c(
                "b-dropdown-item",
                {
                  on: {
                    click: function ($event) {
                      return _vm.goto("/profile")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-user" }),
                  _vm._v(_vm._s(_vm.FormMSG(10, "Profile")) + " "),
                ]
              ),
              _c(
                "b-dropdown-item",
                {
                  on: {
                    click: function ($event) {
                      return _vm.goto("/myproject")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-film" }),
                  _vm._v(" " + _vm._s(_vm.FormMSG(11, "Projects")) + " "),
                ]
              ),
              _c(
                "b-dropdown-item",
                {
                  on: {
                    click: function ($event) {
                      return _vm.goto(`/my-dashboard${_vm.version}`)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-bar-chart" }),
                  _vm._v(
                    " " + _vm._s(_vm.FormMSG(12, "My dashboard")) + "\n\t\t\t"
                  ),
                ]
              ),
              _c("b-dropdown-item", { on: { click: _vm.privacyPolicy } }, [
                _c("i", { staticClass: "icon-eyeglass" }),
                _vm._v(" " + _vm._s(_vm.FormMSG(13, "Privacy Policy")) + " "),
              ]),
              _c(
                "b-dropdown-item",
                {
                  on: {
                    click: function ($event) {
                      return _vm.openTipOfTheDay()
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-lightbulb-o",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.FormMSG(19999, "Tip")) +
                      "\n\t\t\t"
                  ),
                ]
              ),
              _vm.isFilmSingle
                ? _c("b-dropdown-item", { on: { click: _vm.unsubscribe } }, [
                    _c("i", {
                      staticClass: "fa fa-user-times",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(
                      " " + _vm._s(_vm.FormMSG(23, "Unsubscribe")) + "\n\t\t\t"
                    ),
                  ])
                : _vm._e(),
              _c("b-dropdown-item", { on: { click: _vm.showVersion } }, [
                _c("i", { staticClass: "fa fa-book fa-fw" }),
                _vm._v(" " + _vm._s(_vm.FormMSG(16, "About")) + " "),
              ]),
              _vm.showTranslatorItem
                ? _c(
                    "b-dropdown-item",
                    { on: { click: _vm.toggleTranslationInfo } },
                    [
                      _c("i", { staticClass: "fa fa-language" }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.showTranslationInfoLabel()) +
                          "\n\t\t\t"
                      ),
                    ]
                  )
                : _vm._e(),
              _c("b-dropdown-divider"),
              _c("b-dropdown-item", { on: { click: _vm.logout } }, [
                _c("i", { staticClass: "fa fa-lock" }),
                _vm._v(" " + _vm._s(_vm.FormMSG(14, "Logout"))),
              ]),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "header-class": "header-class-modal-doc-package",
            title: _vm.FormMSG(17, "About"),
            "ok-variant": "light",
            "ok-only": "",
          },
          on: {
            ok: function ($event) {
              _vm.openAboutModal = false
            },
          },
          model: {
            value: _vm.openAboutModal,
            callback: function ($$v) {
              _vm.openAboutModal = $$v
            },
            expression: "openAboutModal",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "col text-center w-100",
              staticStyle: { color: "rgba(6, 38, 62, 0.84)" },
            },
            [
              _vm.about.stripe
                ? _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-row justify-content-center pb-2 w-100",
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold text-right",
                          staticStyle: { width: "40%" },
                        },
                        [_vm._v(_vm._s(_vm.FormMSG(18, "Stripe")))]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "pl-2 text-left",
                          staticStyle: { width: "60%" },
                        },
                        [_vm._v(_vm._s(_vm.about.stripe))]
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-row justify-content-center pb-2 w-100",
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "font-weight-bold text-right",
                      staticStyle: { width: "40%" },
                    },
                    [_vm._v(_vm._s(_vm.FormMSG(19, "Front Version")))]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "pl-2 text-left",
                      staticStyle: { width: "60%" },
                    },
                    [_vm._v(_vm._s(_vm.about.frontVersion))]
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "d-flex flex-row justify-content-center w-100" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "font-weight-bold text-right",
                      staticStyle: { width: "40%" },
                    },
                    [_vm._v(_vm._s(_vm.FormMSG(20, "Back Version")))]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "text-uppercase text-left pl-2",
                      staticStyle: { width: "60%" },
                    },
                    [_vm._v(_vm._s(_vm.about.backVersion))]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }