var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "d-lg-none backbtn-toggler",
      attrs: { display: "sm", mobile: "true", type: "button" },
      on: {
        click: function ($event) {
          return _vm.$router.go(-1)
        },
      },
    },
    [_c("ArrowIcon")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }