var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.optionsForLanguage.length > 0
    ? _c(
        "div",
        { staticClass: "language-toggler dropdown" },
        [
          _vm.$screen.width >= 992
            ? _c(
                "b-dropdown",
                {
                  attrs: {
                    variant: "outline-secondary",
                    size: "sm",
                    text: _vm.curArrayItem.text,
                  },
                },
                [
                  _c(
                    "b-dropdown-header",
                    { attrs: { id: "dropdown-header-label" } },
                    [
                      _vm._v(
                        "\n\t\t\t" +
                          _vm._s(
                            _vm.FormMSG(10, "Switch to another language")
                          ) +
                          "\n\t\t"
                      ),
                    ]
                  ),
                  _vm._l(_vm.langArray, function (lang) {
                    return _c(
                      "b-dropdown-item-button",
                      {
                        key: lang.value,
                        attrs: { action: "" },
                        on: {
                          click: function ($event) {
                            return _vm.changeLanguage(lang)
                          },
                        },
                      },
                      [_vm._v("\n\t\t\t" + _vm._s(lang.text) + "\n\t\t")]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm.$screen.width < 992
            ? _c(
                "b-dropdown",
                {
                  attrs: {
                    size: "sm",
                    variant: "link",
                    "toggle-class": "text-decoration-none",
                    "no-caret": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "button-content",
                        fn: function () {
                          return [
                            _c(
                              "b-button",
                              {
                                staticClass: "mx-0",
                                staticStyle: { width: "32px" },
                                attrs: {
                                  variant: "outline-primary",
                                  size: "sm",
                                  disabled: _vm.isLangueChangeLoading,
                                },
                              },
                              [
                                _vm.isLangueChangeLoading
                                  ? _c("b-spinner", { attrs: { small: "" } })
                                  : _c("globe-2", { attrs: { size: 16 } }),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    892118768
                  ),
                },
                [
                  _c(
                    "b-dropdown-header",
                    { attrs: { id: "dropdown-header-label" } },
                    [
                      _vm._v(
                        "\n\t\t\t" +
                          _vm._s(
                            _vm.FormMSG(10, "Switch to another language")
                          ) +
                          "\n\t\t"
                      ),
                    ]
                  ),
                  _vm._l(_vm.langArray, function (lang) {
                    return _c(
                      "b-dropdown-item",
                      {
                        key: lang.value,
                        attrs: {
                          action: "",
                          active: _vm.language === lang.value,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.changeLanguage(lang)
                          },
                        },
                      },
                      [_vm._v("\n\t\t\t" + _vm._s(lang.text) + "\n\t\t")]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }