<template>
	<div class="other-file-viwer--filepreviewer--component-wrapper">
		<span class="text-info">{{ FormMSG(6, 'This file extention is not readable here you can open it here') }}</span>

		<b-button variant="success btn-block" @click="handleOpenFile">
			{{ FormMSG(33, 'Open file') }}
		</b-button>
	</div>
</template>

<script>
import { isNil } from '@/shared/utils';
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'OtherFileViewerComponent',
	props: {
		src: { type: String, required: true, default: null },
		manager: { type: Object, required: true, default: () => {} }
	},
	mixins: [languageMessages],
	methods: {
		handleOpenFile() {
			if (isNil(this.src)) return;
			this.manager.states.currFileDetails.status = 3;
			window.open(this.src); // in a new window
			return;
		}
	}
};
</script>

<style lang="css">
span.text-info {
	margin-bottom: 15px;
	display: inline-block;
	font-family: 'Roboto', sans-serif;
	font-size: 0.85rem;
	color: #1f2021;
}
</style>
