<template>
	<div class="sign-document-file-previewer--component-wrapper">
		<div v-if="manager.states.options.showStatusFileTag && $has(currFileDetails.status)" class="wrap-status mr-3">
			<div class="wrap-status" :class="currFileDetails.validatedClass">
				<div :class="`status ${getStatusClass(currFileDetails.status)}`">
					{{ rendDocumentValidatedStatus(currFileDetails.status) }}
				</div>
			</div>
		</div>
		<!-- <b-button v-if="isFileSignable" :disabled="isStatusLoading" @click="confirmPasswordPrompt">
			<b-spinner v-if="isStatusLoading" small style="margin-right: 15px" />
			<span v-else>
				{{ FormMSG(12321, 'Sign') }}
			</span>
		</b-button> -->
		<b-button
			size="sm"
			class="btn bg-transparent border-0"
			v-if="isFileSignable"
			:disabled="isStatusLoading"
			@click="confirmPasswordPrompt"
			v-b-tooltip.hover.left
			:title="FormMSG(12321, 'Sign')"
		>
			<b-spinner v-if="isStatusLoading" small style="margin-right: 15px" />
			<component :is="getLucideIcon('FileSignature')" :color="ICONS.EDIT.color" :size="20" v-else />
		</b-button>
		<modal-for-sign-document
			:open="showModalForSignDocument"
			:document="documentSelected"
			:requester-id="requesterId"
			:user-id="userId"
			@modal-for-sign-document:document-signed="handleModalForSignDocumentDocumentSigned"
			@modal-for-sign-document:close="handleModalForSignDocumentClose"
		/>
		<oodrive-sign-contract-modal
			v-model="isOodriveSignContractOpen"
			:url="urlOodrive"
			:delivery-id="documentSelected ? documentSelected.firstDeliveryId : 0"
			:doc-signed-xid="documentSelected ? documentSelected.docSignedXid : ''"
			@oodrive-sign-contract-modal:close="onOodriveSignContractClosed"
			@oodrive-sign-contract-modal:documentUpdateStatus="onOodriveSignContractStatusUpdated"
		/>
	</div>
</template>

<script>
//  badge-${data.item.validatedClass}
// import gql from 'graphql-tag';
import { isNil } from '@/shared/utils';
import { store } from '@/store/index';
import { signDocument, getDocument, getOodriveSignatureLink } from '@/cruds/document.crud';
// import passwordConfirmMixin from '@/mixins/passwordConfirm.mixin'
import statusMixin from '@/mixins/status.mixin';
import languageMessages from '@/mixins/languageMessages';
import ModalForSignDocument from '@/components/Modals/ModalForSignDocument';
import { classDocumentStatus } from '@/shared/global-status';
import OodriveSignContractModal from '@/components/Modals/OodriveSignContractModal';
import { OODRIVE_VALID_DOCUMENT_EXT } from '@/shared/constants';
import globalMixin from '@/mixins/global.mixin';

const signableFilesExt = ['pdf', 'xlsx', 'docx'];

export default {
	name: 'SignDocumentToolComponent',
	mixins: [statusMixin, languageMessages, globalMixin],
	components: {
		ModalForSignDocument,
		OodriveSignContractModal
	},
	props: {
		manager: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	data() {
		return {
			isStatusLoading: false,
			isDocumentSignLoading: false,
			isCurrFileCanBeSign: true,
			showModalForSignDocument: false,
			documentSelected: null,
			requesterId: 0,
			userId: 0,
			isOodriveSignContractOpen: false,
			urlOodrive: ''
		};
	},
	created() {
		this.$bus.$on('filePreviewer:sign-successfully-done', (docXid) => {
			if (isNil(docXid) || this.xid !== docXid) return;
			this.isCurrFileCanBeSign = false;
		});
	},
	computed: {
		isFileSignable() {
			const isDoc = this.manager.states.options.presentationMode === 'doc';
			const ext = this.manager.states.currentActiveSlideExt;
			const optCheck = this.manager.states.options.showSignFileButton;
			const toSign = this.currFileDetails.toSign || false;
			const hasGoodStatus = this.currFileDetails.status === 3 || false;

			return isDoc && signableFilesExt.includes(ext) && optCheck && toSign && this.isCurrFileCanBeSign && hasGoodStatus;
		},
		/**
		 * @return {String}
		 */
		xid() {
			return this.manager.states.currentActiveSlide;
		},
		/**
		 * @return {Object}
		 */
		currFileDetails() {
			return this.manager.states.currFileDetails;
		}
	},
	methods: {
		getStatusClass(status) {
			return classDocumentStatus(status);
		},
		handleModalForSignDocumentDocumentSigned() {
			this.manager.states.currFileDetails.status = 4;
			this.isCurrFileCanBeSign = false;
		},
		handleModalForSignDocumentClose() {
			this.showModalForSignDocument = false;
		},
		async handleSignDocument() {
			const docId = this.currFileDetails.id;
			const userId = await store.userID();
			this.isDocumentSignLoading = true;
			try {
				await signDocument({ docId, userId });
				this.manager.stream.$emit('file-signed-successfully', true);
				this.$toast({
					message: this.FormMSG(32123, 'Your document has been successfully signed'),
					title: this.FormMSG(3213214, 'Document signed'),
					variant: 'success'
				});
			} catch (err) {
				this.$toast({
					message: err.message,
					title: this.FormMSG(368, 'Error'),
					variant: 'danger'
				});
			}
			this.isDocumentSignLoading = false;
		},
		async confirmPasswordPrompt() {
			this.documentSelected = this.currFileDetails;
			if (OODRIVE_VALID_DOCUMENT_EXT.includes(this.documentSelected.ext)) {
				this.$emit('sign-document-tool:watingForOodrive', true);
				await getOodriveSignatureLink(this.documentSelected.firstDeliveryId)
					.then((result) => {
						this.$emit('sign-document-tool:watingForOodrive', false);
						this.urlOodrive = result;
						this.isOodriveSignContractOpen = true;
					})
					.catch((error) => {
						console.log({ error });
						this.$emit('sign-document-tool:watingForOodrive', false);
					})
					.finally(() => {
						this.$emit('sign-document-tool:watingForOodrive', false);
					});
			} else {
				this.showModalForSignDocument = true;
				this.parseUrlForGetRequesterId();
				// this.$bus.$emit('filePreviewer:sign-document-action', {
				//   item: this.currFileDetails
				// })
			}
		},
		parseUrlForGetRequesterId() {
			const url = this.documentSelected.src;
			const urlSplited = url.split('documentForUser')[1];
			this.requesterId = parseInt(urlSplited.split('/')[1]);
			this.userId = parseInt(urlSplited.split('/')[2]);
		},
		onOodriveSignContractClosed() {
			this.isOodriveSignContractOpen = false;
			this.$emit('sign-document-tool:closeViewer');
		},
		async onOodriveSignContractStatusUpdated(payload) {
			if (!_.isNil(payload)) {
				this.isCurrFileCanBeSign = false;
				this.$emit('sign-document-tool:closeViewer');
				this.$emit('sign-document-tool:documentUpdateStatus', payload);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.sign-document-file-previewer--component-wrapper {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-left: 10px;

	&:empty {
		padding-left: 0;
	}
}
.wrap-status {
	justify-content: unset !important;
}
</style>
