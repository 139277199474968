var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "word-md-file--component-wrapper" },
    [
      !_vm.isDocViewerNotAvalable
        ? _c("VueDocPreview", {
            attrs: { id: _vm.containerId, value: _vm.src, type: _vm.rendType },
          })
        : _c(
            "b-container",
            {
              staticClass: "fallback-container",
              staticStyle: { "padding-top": "200px" },
            },
            [
              _c(
                "b-alert",
                {
                  staticClass: "push_b_40",
                  attrs: { variant: "info", show: "" },
                },
                [
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(
                        _vm.FormMSG(1, "You've been redirected automatically")
                      ) +
                      "\n\t\t"
                  ),
                ]
              ),
              _c(
                "b-button",
                {
                  attrs: { block: "", variant: "primary" },
                  on: { click: _vm.openInBrowser },
                },
                [
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(_vm.FormMSG(2, "Open in browser")) +
                      "\n\t\t"
                  ),
                ]
              ),
              _c(
                "b-button",
                {
                  attrs: { block: "", variant: "success" },
                  on: { click: _vm.downloadFile },
                },
                [
                  _vm._v(
                    "\n\t\t\t" + _vm._s(_vm.FormMSG(3, "Download")) + "\n\t\t"
                  ),
                ]
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }