var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.useNotification.totalNotification > 0
    ? _c(
        "div",
        {
          staticClass: "account-notifications dropdown",
          style: `${_vm.$screen.width <= 576 ? "" : "margin-right: 25px;"}`,
        },
        [
          _c(
            "b-dropdown",
            {
              class: `notifications-dropdown${
                _vm.$screen.width <= 576 ? " fix-notifications-for-pwa" : ""
              }${_vm.$isPwa() ? " pwa" : ""}`,
              staticStyle: { width: "30px" },
              attrs: {
                variant: "link",
                size: "md",
                "toggle-class": "text-decoration-none",
                right: "",
                "no-caret": "",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "button-content",
                    fn: function () {
                      return [
                        _c("bell", { attrs: { size: 18 } }),
                        _c(
                          "b-badge",
                          {
                            staticClass: "badge-header badge-variant",
                            attrs: { pill: "" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.statusNotifications
                                  ? _vm.useNotification.totalNotification
                                  : _vm.totalNotification
                              )
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                381485467
              ),
            },
            [
              _c(
                "b-dropdown-header",
                { attrs: { id: "dropdown-header-label" } },
                [
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(_vm.FormMSG(10, "Your notifications")) +
                      "\n\t\t"
                  ),
                ]
              ),
              _vm.statusNotifications
                ? [
                    _vm.useNotification.totalTsToDelete > 0
                      ? _c(
                          "b-dropdown-item",
                          {
                            attrs: {
                              action: "",
                              href: "#",
                              to: "/time-cards/planning",
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t" +
                                _vm._s(
                                  _vm.FormMSG(40, "Request for deleting")
                                ) +
                                " "
                            ),
                            _c(
                              "b-badge",
                              {
                                staticClass: "badge-variant",
                                attrs: { pill: "" },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.useNotification.totalTsToDelete)
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.useNotification.totalTsToValidate > 0
                      ? _c(
                          "b-dropdown-item",
                          {
                            attrs: {
                              action: "",
                              href: "#",
                              to: "/time-cards/planning",
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t" +
                                _vm._s(
                                  _vm.FormMSG(13, "Timesheets to validate")
                                ) +
                                " "
                            ),
                            _c(
                              "b-badge",
                              {
                                staticClass: "badge-variant",
                                attrs: { pill: "" },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.useNotification.totalTsToValidate)
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.useNotification.totalTsRefused > 0
                      ? _c(
                          "b-dropdown-item",
                          {
                            attrs: {
                              action: "",
                              href: "#",
                              to: "/time-cards/planning",
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t" +
                                _vm._s(_vm.FormMSG(16, "Timesheets refused")) +
                                " "
                            ),
                            _c(
                              "b-badge",
                              {
                                staticClass: "badge-variant",
                                attrs: { pill: "" },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.useNotification.totalTsRefused)
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                : [
                    _vm.totalDocToRead > 0
                      ? _c(
                          "b-dropdown-item",
                          {
                            attrs: {
                              size: "sm",
                              action: "",
                              href: "#",
                              to: "/mydocuments",
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t" +
                                _vm._s(_vm.FormMSG(11, "New Documents")) +
                                " "
                            ),
                            _c(
                              "b-badge",
                              {
                                staticClass: "badge-variant",
                                attrs: { pil: "" },
                              },
                              [_vm._v(_vm._s(_vm.totalDocToRead))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.totalAccToRead > 0
                      ? _c(
                          "b-dropdown-item",
                          {
                            attrs: {
                              action: "",
                              href: "#",
                              to: "/myaccomodations",
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t" +
                                _vm._s(_vm.FormMSG(12, "New accomodations")) +
                                " "
                            ),
                            _c(
                              "b-badge",
                              {
                                staticClass: "badge-variant",
                                attrs: { pill: "" },
                              },
                              [_vm._v(_vm._s(_vm.totalAccToRead))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.totalTsToValidate > 0
                      ? _c(
                          "b-dropdown-item",
                          {
                            attrs: { action: "", href: "#", to: "/validateTS" },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t" +
                                _vm._s(
                                  _vm.FormMSG(13, "Timesheets to validate")
                                ) +
                                " "
                            ),
                            _c(
                              "b-badge",
                              {
                                staticClass: "badge-variant",
                                attrs: { pill: "" },
                              },
                              [_vm._v(_vm._s(_vm.totalTsToValidate))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.totalExpToValidate > 0
                      ? _c(
                          "b-dropdown-item",
                          {
                            attrs: {
                              action: "",
                              href: "#",
                              to: "/validateExp",
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t" +
                                _vm._s(
                                  _vm.FormMSG(15, "Expenses to validate")
                                ) +
                                " "
                            ),
                            _c(
                              "b-badge",
                              {
                                staticClass: "badge-variant",
                                attrs: { pill: "" },
                              },
                              [_vm._v(_vm._s(_vm.totalExpToValidate))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.totalPoToValidate > 0
                      ? _c(
                          "b-dropdown-item",
                          {
                            attrs: { action: "", href: "#", to: "/validatePO" },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t" +
                                _vm._s(
                                  _vm.FormMSG(14, "Purchase orders to validate")
                                ) +
                                " "
                            ),
                            _c(
                              "b-badge",
                              {
                                staticClass: "badge-variant",
                                attrs: { pill: "" },
                              },
                              [_vm._v(_vm._s(_vm.totalPoToValidate))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.totalTsRefused > 0
                      ? _c(
                          "b-dropdown-item",
                          {
                            attrs: {
                              action: "",
                              href: "#",
                              to: "/mytimesheets",
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t" +
                                _vm._s(_vm.FormMSG(16, "Timesheets refused")) +
                                " "
                            ),
                            _c(
                              "b-badge",
                              {
                                staticClass: "badge-variant",
                                attrs: { pill: "" },
                              },
                              [_vm._v(_vm._s(_vm.totalTsRefused))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.totalExpRefused > 0
                      ? _c(
                          "b-dropdown-item",
                          {
                            attrs: { action: "", href: "#", to: "/myexpenses" },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t" +
                                _vm._s(_vm.FormMSG(17, "Expenses refused")) +
                                " "
                            ),
                            _c(
                              "b-badge",
                              {
                                staticClass: "badge-variant",
                                attrs: { pill: "" },
                              },
                              [_vm._v(_vm._s(_vm.totalExpRefused))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.totalPoRefused > 0
                      ? _c(
                          "b-dropdown-item",
                          { attrs: { action: "", href: "#", to: "/mypo" } },
                          [
                            _vm._v(
                              "\n\t\t\t\t" +
                                _vm._s(
                                  _vm.FormMSG(18, "Purchase orders refused")
                                ) +
                                " "
                            ),
                            _c(
                              "b-badge",
                              {
                                staticClass: "badge-variant",
                                attrs: { pill: "" },
                              },
                              [_vm._v(_vm._s(_vm.totalPoRefused))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.totalExpItemRefused > 0
                      ? _c(
                          "b-dropdown-item",
                          {
                            attrs: { action: "", href: "#", to: "/myexpenses" },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t" +
                                _vm._s(
                                  _vm.FormMSG(19, "Expense items refused")
                                ) +
                                " "
                            ),
                            _c(
                              "b-badge",
                              {
                                staticClass: "badge-variant",
                                attrs: { pill: "" },
                              },
                              [_vm._v(_vm._s(_vm.totalExpItemRefused))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }