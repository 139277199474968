var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sign-document-file-previewer--component-wrapper" },
    [
      _vm.manager.states.options.showStatusFileTag &&
      _vm.$has(_vm.currFileDetails.status)
        ? _c("div", { staticClass: "wrap-status mr-3" }, [
            _c(
              "div",
              {
                staticClass: "wrap-status",
                class: _vm.currFileDetails.validatedClass,
              },
              [
                _c(
                  "div",
                  {
                    class: `status ${_vm.getStatusClass(
                      _vm.currFileDetails.status
                    )}`,
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(
                          _vm.rendDocumentValidatedStatus(
                            _vm.currFileDetails.status
                          )
                        ) +
                        "\n\t\t\t"
                    ),
                  ]
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm.isFileSignable
        ? _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.left",
                  modifiers: { hover: true, left: true },
                },
              ],
              staticClass: "btn bg-transparent border-0",
              attrs: {
                size: "sm",
                disabled: _vm.isStatusLoading,
                title: _vm.FormMSG(12321, "Sign"),
              },
              on: { click: _vm.confirmPasswordPrompt },
            },
            [
              _vm.isStatusLoading
                ? _c("b-spinner", {
                    staticStyle: { "margin-right": "15px" },
                    attrs: { small: "" },
                  })
                : _c(_vm.getLucideIcon("FileSignature"), {
                    tag: "component",
                    attrs: { color: _vm.ICONS.EDIT.color, size: 20 },
                  }),
            ],
            1
          )
        : _vm._e(),
      _c("modal-for-sign-document", {
        attrs: {
          open: _vm.showModalForSignDocument,
          document: _vm.documentSelected,
          "requester-id": _vm.requesterId,
          "user-id": _vm.userId,
        },
        on: {
          "modal-for-sign-document:document-signed":
            _vm.handleModalForSignDocumentDocumentSigned,
          "modal-for-sign-document:close": _vm.handleModalForSignDocumentClose,
        },
      }),
      _c("oodrive-sign-contract-modal", {
        attrs: {
          url: _vm.urlOodrive,
          "delivery-id": _vm.documentSelected
            ? _vm.documentSelected.firstDeliveryId
            : 0,
          "doc-signed-xid": _vm.documentSelected
            ? _vm.documentSelected.docSignedXid
            : "",
        },
        on: {
          "oodrive-sign-contract-modal:close": _vm.onOodriveSignContractClosed,
          "oodrive-sign-contract-modal:documentUpdateStatus":
            _vm.onOodriveSignContractStatusUpdated,
        },
        model: {
          value: _vm.isOodriveSignContractOpen,
          callback: function ($$v) {
            _vm.isOodriveSignContractOpen = $$v
          },
          expression: "isOodriveSignContractOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }