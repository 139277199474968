module.exports = [
	{
		name: 'My Profile',
		msgId: 71,
		namePme: 'Employee',
		msgIdPme: 171,
		open: true,
		class: '',
		icon: 'icon-user',
		titleUpdatable: true,
		wrapper: {
			element: '',
			attributes: {}
		},
		itemAttr: { id: 'drop-administration' },
		savedChildren: [],
		children: [
			{
				name: 'My Profile',
				url: '/profile',
				// icon: "icon-user",
				msgId: 3
			},
			{
				name: 'My Project',
				url: '/myproject',
				// icon: "icon-film",
				filmOnly: true,
				msgId: 2
			},

			{
				name: 'My Office',
				url: '/myoffice',
				// icon: "icon-film",
				pmeOnly: true,
				msgId: 102
			},
			{
				name: 'My Tasks',
				url: '/mytasks',
				notForFilm: true,
				notForSingleUser: true,
				msgId: 156
			},
			{
				name: 'My Planning',
				url: '/myplannings',
				// icon: "icon-folder",
				notForFilm: true,
				notForSingleUser: true,
				// pmeOnly: true,
				// agencyOnly: true,
				msgId: 90878
			}
		]
	},
	{
		name: 'Entry for Crew',
		msgId: 106,
		namePme: 'EntryForCrew',
		msgIdPme: 172,
		open: true,
		class: '',
		notForSingleUser: true,
		icon: 'icon-people',
		titleUpdatable: true,
		wrapper: {
			element: '',
			attributes: {}
		},
		itemAttr: { id: 'drop-administration' },
		savedChildren: [],
		accessForCrew: true,
		notForMobile: true,
		children: [
			{
				name: 'Timesheets',
				url: '/timesheetsEntryCrew',
				// icon: "fa fa-lightbulb-o",
				msgId: 107,
				forCrewTimesheet: true
			},
			{
				name: 'Expenses',
				url: '/expensesEntryCrew',
				// icon: "fa fa-address-book",
				notForSingleUser: true,
				msgId: 108,
				forCrewExpense: true
			},
			{
				name: 'PO',
				url: '/poEntryCrew',
				// icon: "icon-book-open",
				msgId: 109,
				forCrewPO: true
			}
		]
	},
	{
		name: 'Production',
		namePme: 'Management',
		notForSingleUser: true,
		prodOnly: true,
		open: false,
		class: '',
		icon: 'icon-graph',
		wrapper: {
			element: '',
			attributes: {}
		},
		msgId: 18,
		msgIdPme: 118,
		itemAttr: { id: 'drop-production' },
		savedChildren: [],
		titleUpdatable: true,
		children: [
			{
				name: 'Offers',
				url: '/offers',
				// icon: "icon-folder",
				// prodOnly: true,
				notForFilm: true,
				// pmeOnly: true,
				// agencyOnly: true,
				msgId: 90256
			},
			{
				name: 'Crew Setup',
				url: '/project/users',
				// icon: "icon-people",
				// prodOnly: true,
				notForMobile: true,
				canAccessUsersOnly: true,
				msgId: 300
			},
			{
				name: 'Contacts',
				url: '/contacts',
				// icon: "fa fa-address-book",
				notForSingleUser: true,
				msgId: 10
			},
			{
				name: 'Tasks',
				url: '/tasks',
				// icon: "icon-folder",
				// prodOnly: true,
				// pmeOnly: true,
				// agencyOnly: true,
				notForFilm: true,
				msgId: 90876
			},
			{
				name: 'Plannings',
				url: '/plannings',
				// icon: "icon-folder",
				// prodOnly: true,
				notForFilm: true,
				// pmeOnly: true,
				// agencyOnly: true,
				msgId: 90877
			},
			{
				name: 'Documents',
				url: '/documents',
				// icon: "icon-folder",
				notForMobile: true,
				// prodOnly: true,
				msgId: 13
			},
			{
				name: 'Accomodations',
				url: '/myaccomodations',
				notForSingleUser: true,
				// icon: 'fa fa-building-o',
				// // prodOnly: true,
				msgId: 14
			},
			{
				name: 'Locations',
				url: '/locations',
				// icon: "icon-credit-card",
				// adminOnly: true,
				// prodOnly: true,
				filmOnly: true,
				msgId: 25
			},
			{
				name: 'Transports',
				url: '/TransportsList',
				// icon: "icon-credit-card",
				//filmOnly: true,
				// notForFilm: true,
				msgId: 21231235
			},
			{
				name: 'Clients',
				url: '/clientsList',
				// icon: "fa fa-building-o",
				pmeOnly: true,
				msgId: 1232434
			},
			{
				name: 'Exports',
				url: '/exports',
				// icon: "icon-cloud-download",
				// prodOnly: true,
				notForMobile: true,
				msgId: 24
			},
			{
				name: 'Imports',
				url: '/imports',
				notForMobile: true,
				// icon: "icon-cloud-download",
				// prodOnly: true,
				msgId: 241
			}
		]
	},
	// Fin
	{
		name: 'Finance',
		namePme: 'Finance',
		msgIdPme: 1101,
		useFinance: true,
		open: false,
		class: '',
		wrapper: {
			element: '',
			attributes: {}
		},
		msgId: 1110,
		itemAttr: { id: 'drop-administration' },
		icon: 'icon-book-open',
		savedChildren: [],
		titleUpdatable: true,
		children: [
			{
				name: 'Suppliers',
				url: '/LoadGreenSuppliers',
				// icon: "icon-book-open",
				msgId: 620
			},
			{
				name: 'My PO',
				url: '/mypo',
				// icon: "icon-credit-card",
				notForSingleUser: true,
				msgId: 7
			},
			{
				name: 'My Expenses',
				url: '/myexpenses',
				// icon: "icon-credit-card",
				msgId: 6
			},
			{
				name: 'My Timesheets',
				url: '/mytimesheets',
				// icon: "icon-clock",
				msgId: 5
			},
			{
				name: 'Timesheets planning',
				url: '/time-cards/planning',
				notForMobile: true,
				notForSingleUser: true,
				// icon: "icon-clock",
				msgId: 845
			},
			{
				name: 'Budget GL',
				url: '/budget',
				notForMobile: true,
				// icon: "icon-wallet",
				// prodOnly: true,
				// canAccessBudgetOnly: true,
				msgId: 210
			}
		]
	},
	{
		name: 'Green',
		namePme: 'Green',
		msgIdPme: 11010,
		useGreen: true,
		open: false,
		class: '',
		wrapper: {
			element: '',
			attributes: {}
		},
		msgId: 11100,
		itemAttr: { id: 'drop-administration' },
		icon: 'fa fa-leaf',
		savedChildren: [],
		titleUpdatable: true,
		children: [
			{
				name: 'Carbon template',
				url: '/green/carbon-template',
				notForMobile: true,
				// icon: "icon-key",
				msgId: 5891
			},
			{
				name: 'Carbon',
				notForMobile: true,
				url: '/green/list', // id = 0 utilisé pour projet en cours (token)
				// icon: "icon-key",
				msgId: 5242
			},
			{
				name: 'Reporting',
				notForMobile: true,
				haveActiveReporting: true,
				url: '/green/reporting',
				// canAccessGreenReportingOnly: true,
				// icon: "fa fa-lightbulb-o",
				msgId: 550
			},
			{
				name: 'Carbon removal',
				url: '/carbon-removal',
				notForMobile: true,
				// prodOnly: true,
				// canAccessGreenReportingOnly: true,
				msgId: 1697
			},
			{
				name: 'Waste',
				url: '/green/waste',
				notForMobile: true,
				notForSingleUser: true,
				// icon: "fa fa-lightbulb-o",
				msgId: 898
			},
			{
				name: 'My tip of the day',
				url: '/myTipOfTheDay',
				// icon: "fa fa-lightbulb-o",
				msgId: 55
			}
		]
	},

	{
		name: 'Configuration',
		canAccessConfigOnly: true,
		notForSingleProject: true,
		class: '',
		notForMobile: true,
		open: false,
		icon: 'icon-settings',
		wrapper: {
			element: '',
			attributes: {}
		},
		msgId: 26,
		itemAttr: { id: 'drop-configuration' },
		savedChildren: [],
		children: [
			{
				name: 'Validations',
				url: '/project/validations', // id = 0 utilisé pour projet en cours (token)
				// icon: "icon-key",
				canAccessConfigOnly: true,
				msgId: 33
			},
			{
				name: 'Project',
				namePme: 'Legal entity',
				canAccessConfigOnly: true,
				url: '/settings',
				// icon: "icon-briefcase",
				msgId: 12,
				msgIdPme: 112
			},
			// {
			// 	name: 'Document package',
			// 	canAccessConfigOnly: true,
			// 	url: '/document-package',
			// 	// icon: "icon-briefcase",
			// 	msgId: 9655
			// },
			{
				name: 'Task types',
				url: '/task-types',
				canAccessConfigOnly: true,
				notForFilm: true,
				msgId: 256
			},
			{
				name: 'Teams',
				url: '/teams',
				canAccessConfigOnly: true,
				notForFilm: true,
				msgId: 257
			}
		]
	},
	{
		name: 'Translations',
		translatorOnly: true,
		show: false,
		icon: 'icon-book-open',
		class: '',
		open: false,
		wrapper: {
			element: '',
			attributes: {}
		},
		msgId: 40,
		itemAttr: { id: 'drop-translation' },
		savedChildren: [],
		children: [
			{
				name: 'Translations',
				url: '/translation',
				// icon: "icon-book-open",
				translatorOnly: true,
				msgId: 40
			}
		]
	},
	{
		name: 'TheGreenShot',
		devOnly: true,
		show: false,
		icon: 'icon-star',
		class: '',
		open: false,
		wrapper: {
			element: '',
			attributes: {}
		},
		msgId: 41,
		itemAttr: { id: 'drop-translation' },
		savedChildren: [],
		children: [
			{
				name: 'Translations',
				url: '/translation',
				// icon: "icon-book-open",
				devOnly: true,
				msgId: 40
			},
			{
				name: 'All users',
				url: '/allUsers',
				// icon: "icon-people",
				devOnly: true,
				msgId: 36
			},
			{
				name: 'Projects',
				url: '/projects',
				// icon: "icon-film",
				devOnly: true,
				msgId: 37
			},
			{
				name: 'Licenses',
				url: '/licenseList',
				// icon: "icon-book-open",
				devOnly: true,
				msgId: 38
			},
			{
				name: 'Scripts',
				url: '/scripts',
				devOnly: true,
				msgId: 261
			},
			{
				name: 'Film Commission',
				url: '/film-commission',
				devOnly: true,
				msgId: 258
			},
			{
				name: 'Supplier Source',
				url: '/supplier-source',
				devOnly: true,
				msgId: 259
			},
			{
				name: 'Supplier Label',
				url: '/supplier-label',
				devOnly: true,
				msgId: 260
			}
		]
	}
];
