<template v-if="myProfile">
	<div>
		<AppHeaderDropdown right no-caret>
			<template slot="header">
				<div v-lazy-container="{ selector: 'img' }">
					<span v-if="$has(profile)" class="auth-name not-on-screen-mobile px-1">{{ profile.firstName }}</span>
					<!--    <img v-bind:src="this.imageName" class="img1-avatar" style="img1" alt="pict" />  -->
					<img
						:data-src="this.imageName"
						data-error="img/brand/avatar.jpg"
						data-loading="img/brand/tenor.gif"
						class="img-avatar"
						alt="pict"
						style="width: 30px; height: 30px; border-radius: 15px; border: 1px solid #aaa; padding: 1px"
					/>
				</div>
			</template>
			<template slot="dropdown">
				<b-dropdown-header tag="div" class="text-center">
					<strong>{{ FormMSG(15, 'Account') }}</strong>
				</b-dropdown-header>
				<b-dropdown-item v-on:click="goto('/profile')"> <i class="fa fa-user" />{{ FormMSG(10, 'Profile') }} </b-dropdown-item>
				<b-dropdown-item v-on:click="goto('/myproject')"> <i class="fa fa-film" /> {{ FormMSG(11, 'Projects') }} </b-dropdown-item>
				<b-dropdown-item v-on:click="goto(`/my-dashboard${version}`)">
					<i class="fa fa-bar-chart" /> {{ FormMSG(12, 'My dashboard') }}
				</b-dropdown-item>
				<b-dropdown-item v-on:click="privacyPolicy"><i class="icon-eyeglass" /> {{ FormMSG(13, 'Privacy Policy') }} </b-dropdown-item>
				<b-dropdown-item @click="openTipOfTheDay()"
					><i class="fa fa-lightbulb-o" aria-hidden="true"></i>
					{{ FormMSG(19999, 'Tip') }}
				</b-dropdown-item>
				<b-dropdown-item @click="unsubscribe" v-if="isFilmSingle"
					><i class="fa fa-user-times" aria-hidden="true"></i> {{ FormMSG(23, 'Unsubscribe') }}
				</b-dropdown-item>
				<b-dropdown-item v-on:click="showVersion"><i class="fa fa-book fa-fw" /> {{ FormMSG(16, 'About') }} </b-dropdown-item>
				<b-dropdown-item v-if="showTranslatorItem" v-on:click="toggleTranslationInfo"
					><i class="fa fa-language" /> {{ showTranslationInfoLabel() }}
				</b-dropdown-item>
				<b-dropdown-divider />
				<b-dropdown-item v-on:click="logout"><i class="fa fa-lock" /> {{ FormMSG(14, 'Logout') }}</b-dropdown-item>
			</template>
		</AppHeaderDropdown>
		<b-modal
			header-class="header-class-modal-doc-package"
			v-model="openAboutModal"
			:title="FormMSG(17, 'About')"
			ok-variant="light"
			ok-only
			@ok="openAboutModal = false"
		>
			<div class="col text-center w-100" style="color: rgba(6, 38, 62, 0.84)">
				<div class="d-flex flex-row justify-content-center pb-2 w-100" v-if="about.stripe">
					<span class="font-weight-bold text-right" style="width: 40%">{{ FormMSG(18, 'Stripe') }}</span>
					<span class="pl-2 text-left" style="width: 60%">{{ about.stripe }}</span>
				</div>
				<div class="d-flex flex-row justify-content-center pb-2 w-100">
					<span class="font-weight-bold text-right" style="width: 40%">{{ FormMSG(19, 'Front Version') }}</span>
					<span class="pl-2 text-left" style="width: 60%">{{ about.frontVersion }}</span>
				</div>
				<div class="d-flex flex-row justify-content-center w-100">
					<span class="font-weight-bold text-right" style="width: 40%">{{ FormMSG(20, 'Back Version') }}</span>
					<span class="text-uppercase text-left pl-2" style="width: 60%">{{ about.backVersion }}</span>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue';
import { isNil } from '@/shared/utils';
import { store } from '@/store';
// import gql from "graphql-tag";
// import { BASE_URL } from "../vue-apollo";
import languageMessages from '@/mixins/languageMessages';

// import { getTgsWebVersion } from "@/shared/version";
import { myProfile } from '@/cruds/users.crud';
import { myContracts } from '@/cruds/contract.crud';
import { getParamVersion, getBackVersion, getTgsWebVersion } from '@/shared/version';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { unsubscribeSoloUser } from '@/cruds/license.crud';
import { Wand } from 'lucide-vue';
import { GetRandomTipOfDay } from '@/cruds/tipofday.crud';

const defaultImgName = 'img/brand/avatar.jpg';

export default {
	name: 'DefaultHeaderDropdownAccnt',
	mixins: [languageMessages, isSingleProjectMixin],
	components: {
		AppHeaderDropdown,
		Wand
	},
	data() {
		const baseImgPath = process.env.VUE_APP_GQL;
		return {
			itemsCount: 42,
			profile: {},
			showTradInfo: false,
			baseImgPath,
			version: getParamVersion(),
			openAboutModal: false,
			about: {
				stripe: '',
				frontVersion: '',
				backVersion: ''
			}
		};
	},
	computed: {
		showTranslatorItem() {
			return store.isTranslator();
		},
		imageName() {
			const _auth = this.$store.state.auth;
			if (isNil(_auth)) return defaultImgName;

			return isNil(_auth.picture) || _auth.picture.length == 0 ? defaultImgName : `${this.baseImgPath}/imagethumb/${_auth.picture}`;
		}
	},
	mounted() {
		this.$nextTick(async () => {
			await this.loadProfile();
		});
	},
	methods: {
		async openTipOfTheDay() {
			this.tipOfTheDay = await GetRandomTipOfDay(1020);
			this.$bvModal.show('tip-of-the-day-modal');
		},
		async loadProfile() {
			this.profile = await myProfile();
			this.$store.dispatch('setAuthUser', this.profile);
			store.state.myProfile = this.profile;
			const tmp = await myContracts();
			// console.log("in myContract DefaultHeaderDropdownAccnt tmp:",tmp);
			store.state.myContracts = tmp;
			//console.log("in myContract DefaultHeaderDropdownAccnt:",store.state.myContracts);
		},
		async showVersion() {
			const stripe = process.env.VUE_APP_STRIPE_URL;
			const appUrl = process.env.VUE_APP_BASE_URL;
			const backVersion = await getBackVersion();
			// alert(tmp + '\n' + getTgsWebVersion());
			this.about = {
				...this.about,
				stripe: appUrl,
				frontVersion: getTgsWebVersion(),
				backVersion: backVersion.backEndVersion
			};
			this.openAboutModal = true;
		},
		toggleTranslationInfo() {
			if (this.showTradInfo) {
				this.$cookies.set('showTradInfo', 'false');
			} else {
				this.$cookies.set('showTradInfo', 'true');
			}
			this.showTradInfo = !this.showTradInfo;
			window.location.reload(true);
		},
		showTranslationInfoLabel() {
			// check cookie status
			if (this.$cookies.isKey('showTradInfo')) {
				//console.log("cookie showTradInfo=",this.$cookies.get('showTradInfo'));
				this.showTradInfo = this.$cookies.get('showTradInfo') == 'true';
			} else {
				this.$cookies.set('showTradInfo', 'false');
			}
			if (this.showTradInfo) {
				return this.FormMSG(21, 'Hide Translation Info');
			} else {
				return this.FormMSG(22, 'Show Translation Info');
			}
		},
		privacyPolicy() {
			const curLang = store.appLanguage();
			if (curLang === 1) {
				window.open('https://www.thegreenshot.green/fr/termes-et-conditions', '_blank');
			} else {
				window.open('https://www.thegreenshot.green/terms-and-conditions', '_blank');
			}
		},
		logout() {
			store.onLogout(this.$apollo.provider.defaultClient);
			this.$cookies.remove('wizardAlreadyShow');
			this.$router.push('/pages/login').catch(() => {});
		},
		goto(target) {
			this.$router.push(target);
		},
		unsubscribe() {
			this.$bvModal
				.msgBoxConfirm(
					this.FormMSG(24, 'You are about to delete your account in TheGreenShot and all related data. Are you sure you want to continue ?'),
					{
						title: this.FormMSG(25, 'Confirm'),
						size: 'md',
						buttonSize: 'sm',
						okVariant: 'danger',
						okTitle: this.FormMSG(26, 'YES'),
						cancelTitle: this.FormMSG(27, 'NO'),
						footerClass: 'p-2',
						hideHeaderClose: false,
						centered: false
					}
				)
				.then((v) => {
					if (!v) return;
					this.$bvModal
						.msgBoxConfirm(this.FormMSG(28, 'Are you really sure you want to remove your account ?'), {
							title: this.FormMSG(25, 'Confirm'),
							size: 'md',
							buttonSize: 'sm',
							okVariant: 'danger',
							okTitle: this.FormMSG(26, 'YES'),
							cancelTitle: this.FormMSG(27, 'NO'),
							footerClass: 'p-2',
							hideHeaderClose: false,
							centered: false
						})
						.then(async (v) => {
							if (!v) return;

							const result = await unsubscribeSoloUser();
							if (result) {
								this.logout();
							}
						})
						.catch((err) => console.log(err));
				})
				.catch((err) => console.log(err));
		}
	}
};
</script>
