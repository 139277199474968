<template>
	<div class="account-notifications dropdown" :style="`${$screen.width <= 576 ? '' : 'margin-right: 25px;'}`" v-if="useNotification.totalNotification > 0">
		<b-dropdown
			variant="link"
			size="md"
			:class="`notifications-dropdown${$screen.width <= 576 ? ' fix-notifications-for-pwa' : ''}${$isPwa() ? ' pwa' : ''}`"
			toggle-class="text-decoration-none"
			style="width: 30px"
			right
			no-caret
		>
			<template v-slot:button-content>
				<bell :size="18" />
				<b-badge pill class="badge-header badge-variant">{{ statusNotifications ? useNotification.totalNotification : totalNotification }}</b-badge>
			</template>
			<b-dropdown-header id="dropdown-header-label">
				{{ FormMSG(10, 'Your notifications') }}
			</b-dropdown-header>
			<template v-if="statusNotifications">
				<b-dropdown-item action href="#" to="/time-cards/planning" v-if="useNotification.totalTsToDelete > 0">
					{{ FormMSG(40, 'Request for deleting') }} <b-badge pill class="badge-variant">{{ useNotification.totalTsToDelete }}</b-badge>
				</b-dropdown-item>
				<b-dropdown-item action href="#" to="/time-cards/planning" v-if="useNotification.totalTsToValidate > 0">
					{{ FormMSG(13, 'Timesheets to validate') }} <b-badge pill class="badge-variant">{{ useNotification.totalTsToValidate }}</b-badge>
				</b-dropdown-item>
				<b-dropdown-item action href="#" to="/time-cards/planning" v-if="useNotification.totalTsRefused > 0">
					{{ FormMSG(16, 'Timesheets refused') }} <b-badge pill class="badge-variant">{{ useNotification.totalTsRefused }}</b-badge>
				</b-dropdown-item>
			</template>
			<template v-else>
				<b-dropdown-item size="sm" action href="#" to="/mydocuments" v-if="totalDocToRead > 0">
					{{ FormMSG(11, 'New Documents') }} <b-badge pil class="badge-variant">{{ totalDocToRead }}</b-badge></b-dropdown-item
				>
				<b-dropdown-item action href="#" to="/myaccomodations" v-if="totalAccToRead > 0">
					{{ FormMSG(12, 'New accomodations') }} <b-badge pill class="badge-variant">{{ totalAccToRead }}</b-badge>
				</b-dropdown-item>
				<b-dropdown-item action href="#" to="/validateTS" v-if="totalTsToValidate > 0">
					{{ FormMSG(13, 'Timesheets to validate') }} <b-badge pill class="badge-variant">{{ totalTsToValidate }}</b-badge>
				</b-dropdown-item>
				<b-dropdown-item action href="#" to="/validateExp" v-if="totalExpToValidate > 0">
					{{ FormMSG(15, 'Expenses to validate') }} <b-badge pill class="badge-variant">{{ totalExpToValidate }}</b-badge>
				</b-dropdown-item>
				<b-dropdown-item action href="#" to="/validatePO" v-if="totalPoToValidate > 0">
					{{ FormMSG(14, 'Purchase orders to validate') }} <b-badge pill class="badge-variant">{{ totalPoToValidate }}</b-badge>
				</b-dropdown-item>
				<b-dropdown-item action href="#" to="/mytimesheets" v-if="totalTsRefused > 0">
					{{ FormMSG(16, 'Timesheets refused') }} <b-badge pill class="badge-variant">{{ totalTsRefused }}</b-badge>
				</b-dropdown-item>
				<b-dropdown-item action href="#" to="/myexpenses" v-if="totalExpRefused > 0">
					{{ FormMSG(17, 'Expenses refused') }} <b-badge pill class="badge-variant">{{ totalExpRefused }}</b-badge>
				</b-dropdown-item>
				<b-dropdown-item action href="#" to="/mypo" v-if="totalPoRefused > 0">
					{{ FormMSG(18, 'Purchase orders refused') }} <b-badge pill class="badge-variant">{{ totalPoRefused }}</b-badge>
				</b-dropdown-item>
				<b-dropdown-item action href="#" to="/myexpenses" v-if="totalExpItemRefused > 0">
					{{ FormMSG(19, 'Expense items refused') }} <b-badge pill class="badge-variant">{{ totalExpItemRefused }}</b-badge>
				</b-dropdown-item>
			</template>
		</b-dropdown>
	</div>
</template>

<script>
import { store } from '@/store';
import { mapGetters, mapActions } from 'vuex';
import languageMessages from '@/mixins/languageMessages';
import { Bell } from 'lucide-vue';
import { HttpValidation } from '@/helpers/HttpValidation';

export default {
	name: 'HeaderNotifications',
	components: { Bell },
	mixins: [languageMessages],
	data() {
		return { erreur: {}, myerr: '', statusNotifications: false };
	},
	computed: {
		...mapGetters({
			totalNotification: 'header/getTotalNotification',
			totalDocToRead: 'header/getTotalDocToRead',
			totalAccToRead: 'header/getTotalAccToRead',
			totalTsToValidate: 'header/getTotalTsToValidate',
			totalPoToValidate: 'header/getTotalPoToValidate',
			totalExpToValidate: 'header/getTotalExpToValidate',
			totalTsRefused: 'header/getTotalTsRefused',
			totalPoRefused: 'header/getTotalPoRefused',
			totalExpRefused: 'header/getTotalExpRefused',
			totalExpItemRefused: 'header/getTotalExpItemRefused',
			totalTsToDelete: 'header/getTsToDelete'
			// count: 'header/getCount',
		}),
		useNotification() {
			const totalNotification = this.totalTsToValidate + this.totalTsRefused + this.totalTsToDelete;
			return {
				totalNotification: totalNotification,
				totalTsToValidate: this.totalTsToValidate,
				totalTsRefused: this.totalTsRefused,
				totalTsToDelete: this.totalTsToDelete
			};
		}
	},
	async created() {
		await this.getNotifications().catch((error) => {
			console.error({ error });
		});
		setTimeout(() => this.useFimalac(), 100);
	},
	methods: {
		...mapActions({
			getNotifications: 'header/getNotifications'
		}),
		useFimalac() {
			const license = store.state.myProject.license !== undefined ? store.state.myProject.license.license : '';
			const isFimalacLicense = /fimalac/gi.test(license);
			const status = HttpValidation.status && isFimalacLicense;
			this.statusNotifications = status;
		},
		handleClick(screen) {
			if (this.$screen.width <= 576) {
				this.$bvToast.toast(
					`${this.FormMSG(
						21,
						"It's currrently not possible to do approval from a mobile device. To access the approval screens, please use a computer."
					)}`,
					{
						title: this.FormMSG(20, 'Not allowed'),
						variant: 'danger',
						autoHideDelay: 4000
					}
				);
			} else {
				this.$router.push(`/${screen}/?validate=true`);
			}
		}
	}
};
</script>

<style lang="scss">
.fix-notifications-for-pwa {
	margin-left: -2px;
	margin-right: 16px;
	&.show {
		&.pwa .dropdown-menu {
			top: 80px !important;
		}
	}
}

.notifications-dropdown button {
	padding: 0;
}

.badge-header {
	position: absolute !important;
	margin-top: -2px;
	font-size: 77.5%;
}

.badge-variant {
	color: #ea4e2c;
	letter-spacing: 0.03rem;
	background-color: #fcede9;
}
</style>
