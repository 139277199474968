<template>
	<div class="main-container--image-show-room" :class="[{ 'is-panel-open': isCommentPanelOpen }, rendPresentationModeOptionClass]">
		<div class="carousel-container" :class="rendCarouselContainerClass" @mouseenter="handleCarouselMouseEnter" @mouseleave="handleCarouselMouseLeave">
			<div
				v-for="({ xid, src, ext }, i) in imagesList"
				:key="i"
				:class="[{ 'is-active': currentActiveSlide === xid }, `file-type--${presentationMode}`]"
				class="carousel-child"
			>
				<div v-if="isElementPositioningLoad" class="ghost-loading-pinup" :style="ghostLoadingPinUpPosition">
					<b-spinner small />
				</div>
				<PositionedPins v-if="canDropAnotation" :manager="manager" :xid="xid" :dropzone-ref="`droppingZoneRef_${i}`" :image-ref="`slideImg_${i}`" />
				<div
					v-if="canDropAnotation"
					:ref="`droppingZoneRef_${i}`"
					:key="`droppingZoneKey_${xid}_${i}`"
					class="carousel-child--drop-zone-layer"
					:class="{ 'is-drop-zone-active': isAnnotationToolActive }"
					@click="handleDroppedElement($event, i)"
				/>
				<div class="carousel-child--content" :key="`carouselChildKey_${xid}_${i}`">
					<div class="carousel-child--attachment-container">
						<div
							v-if="currentActiveSlide === xid"
							class="carousel-child--attachment-file"
							style="padding: 0px"
							:key="`carouselChildAttachementKey_${xid}_${i}`"
							:class="`file-type--${presentationMode}`"
						>
							<AdobePdfReaderViewer v-if="ext === 'pdf' && !isPwaIOS" :src="src" :xid="xid" :ref="`slidePdf_${i}`" :manager="manager" />
							<img v-else-if="ext.match(/(jpg|jpeg|png|gif)/g)" :ext="ext" :ref="`slideImg_${i}`" :src="src" />
							<WordMDDocViewer
								v-else-if="ext.match(/(docx|pptx|xlsx)/g) && !isBackendLocalhost"
								:ref="`slideImg_${i}`"
								:src="src"
								:ext="ext"
								:xid="xid"
							/>
							<MarkdownDocViewer v-else-if="ext === 'md' || ext === 'txt'" :ref="`slideImg_${i}`" :src="src" :xid="xid" />
							<OtherFileViewer v-else :src="src" :manager="manager" :ref="`slideOther_${i}`" />
						</div>
					</div>
				</div>
			</div>
			<div v-if="!isCommentPanelOpen && isMultiple" class="carousel-control-left" @click="handleChangeSlide(-1)">
				<div class="carousel-control-btn">
					<ArrowIconSVG />
				</div>
			</div>
			<div v-if="!isCommentPanelOpen && isMultiple" class="carousel-control-right" @click="handleChangeSlide(1)">
				<div class="carousel-control-btn">
					<ArrowIconSVG />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getObjectFromValue, isNil } from '@/shared/utils';
import { isFileExtImage } from '@/shared/helpers';
import ArrowIconSVG from '@/components/icons/arrow';
import { getDropData } from '@/shared/positionsMaker';

import PositionedPins from '@/components/Packages/FIlesPreviewer/components/PinsAnnotations/PositionedPins';
// import PdfViewer from '@/components/Packages/FIlesPreviewer/components/FileViewers/PdfViewer'
import AdobePdfReaderViewer from '@/components/Packages/FIlesPreviewer/components/FileViewers/AdobePdfReaderViewer';
import OtherFileViewer from '@/components/Packages/FIlesPreviewer/components/FileViewers/OtherFileViewer';
import WordMDDocViewer from '@/components/Packages/FIlesPreviewer/components/FileViewers/WordMDDocViewer';
import MarkdownDocViewer from '@/components/Packages/FIlesPreviewer/components/FileViewers/MarkdownViewer';

export default {
	name: 'MainContainerImageShowRoomComponent',
	components: {
		ArrowIconSVG,
		PositionedPins,
		OtherFileViewer,
		AdobePdfReaderViewer,
		MarkdownDocViewer,
		WordMDDocViewer
	},
	props: {
		manager: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	data() {
		return {
			isCursorInsideCarouselContainer: false,
			ghostLoadingPinUpPosition: { top: 0, left: 0 }
		};
	},
	computed: {
		/**
		 * @return {Boolean}
		 */
		isBackendLocalhost() {
			return process.env.VUE_APP_GQL.includes('localhost');
		},

		/**
		 * @return {Array}
		 */
		imagesList() {
			return this.manager.states.imagesList;
		},

		/**
		 * @return {String}
		 */
		currentActiveSlide() {
			return this.manager.states.currentActiveSlide;
		},

		/**
		 * @return {Boolean}
		 */
		isMultiple() {
			if (isNil(this.imagesList)) return false;
			return this.imagesList.length > 1;
		},

		/**
		 * @return {Boolean}
		 */
		isAnnotationToolActive() {
			return this.manager.states.isAnnotationToolActive;
		},

		/**
		 * @return {Boolean}
		 */
		isElementPositioningLoad() {
			return this.manager.states.isElementPositioningLoad;
		},

		/**
		 * @return {String}
		 */
		rendPresentationModeOptionClass() {
			return this.manager.states.options.presentationMode !== 'image' ? 'has-horizontal-style' : '';
		},

		/**
		 * @return {Array}
		 */
		rendCarouselContainerClass() {
			return [
				{
					'has-controls-visible': this.isCursorInsideCarouselContainer
				},
				this.rendPresentationModeOptionClass
			];
		},

		/**
		 * @return {Boolean}
		 */
		isPwaIOS() {
			return this.$isPwa() && this.$isIOSDevice();
		},

		/**
		 * @return {String}
		 */
		presentationMode() {
			return this.manager.states.options.presentationMode;
		},

		/**
		 * @return {Boolean}
		 */
		isCommentPanelOpen() {
			return this.manager.states.isCommentPanelOpen;
		},

		/**
		 * @return  {Boolean}
		 */
		canDropAnotation() {
			if (isNil(this.manager.states)) return false;
			const _cfd = this.manager.states.currfiledetails;
			if (isNil(_cfd)) return false;
			return isFileExtImage(_cfd.ext);
		}
	},
	watch: {
		/**
		 * @param {String} xid
		 */
		'manager.states.currentActiveSlide'(xid) {
			this.getCommentStats(xid);
		}
	},
	mounted() {
		this.arrowNavigationHandler();
	},
	methods: {
		arrowNavigationHandler() {
			const vm = this;
			document.addEventListener('keyup', (e) => {
				if (e.keyCode === 37 && !vm.isCommentPanelOpen) vm.handleChangeSlide(-1);
				if (e.keyCode === 39 && !vm.isCommentPanelOpen) vm.handleChangeSlide(1);
			});
		},

		/**
		 * @param {String} xid
		 * @return {String}
		 */
		rendImageFocused(xid) {
			if (isNil(xid)) return;
			return process.env.VUE_APP_PATH_IMG_URL + xid;
		},

		/**
		 * @param {String} xid
		 */
		async getCommentStats(xid) {
			await this.manager.dispatch('getCommentStats', xid);
		},

		/**
		 * @param {Number} value
		 * @return {Emitter}
		 */
		handleChangeSlide(dir) {
			if (this.manager.states.isCommentPanelOpen) return;
			const xid = this.currentActiveSlide;
			const val = getObjectFromValue(this.imagesList, 'xid', xid);
			const currIndex = this.imagesList.indexOf(val);
			const len = this.imagesList.length;
			const curr = (currIndex + (dir % len) + len) % len;
			this.manager.states.currentActiveSlideExt = this.imagesList[curr].ext;
			this.manager.dispatch('setCurrentActiveSlide', this.imagesList[curr].xid);
		},

		handleClickOutsideImg() {
			if (!this.isCursorInsideCarousel) return;
			this.$emit('close', true);
		},

		handleCarouselMouseEnter() {
			this.isCursorInsideCarouselContainer = true;
		},
		handleCarouselMouseLeave() {
			this.isCursorInsideCarouselContainer = false;
		},

		/**
		 * @param {String} xid
		 * @return {Boolean}
		 */
		isDropZoneActive(xid) {
			const _ = this.manager.states.currAnnotationSlide;
			if (isNil(_)) return false;
			return _ === xid;
		},

		/**
		 * @param {Object} dragData - event
		 * @param {Number} index
		 */
		handleDroppedElement(dragData, index) {
			if (!this.isAnnotationToolActive) return;
			this.manager.dispatch('setPositionedAnnotationWaiting', null);

			const { payload, view } = getDropData(dragData, this.$refs[`droppingZoneRef_${index}`], this.$refs[`slideImg_${index}`]);
			this.setGhostLoadingPinUpPosition(view);
			this.manager.dispatch('setPositionedAnnotationWaiting', payload);
			this.manager.dispatch('setPinModalCommentState', true);
		},

		/**
		 * @param {Object} position
		 */
		setGhostLoadingPinUpPosition({ x, y }) {
			this.ghostLoadingPinUpPosition = { top: `${y}px`, left: `${x}px` };
		}
	}
};
</script>

<style lang="scss" scoped>
@import './../../../../assets/scss/tools/mixins';
@import './../../../../assets/scss/tools/media-queries';

.main-container--image-show-room {
	display: flex;
	flex: 1 1 auto;
	flex-direction: row;
	min-height: 1px;

	.carousel-container {
		overflow: hidden;
		position: relative;
		z-index: 100;
		flex: 1 1 auto;
		min-width: 1px;

		.carousel-child {
			left: 0;
			opacity: 0;
			position: absolute;
			top: 0;
			width: 100%;
			@include size(f);

			border: 24px solid transparent;
			box-sizing: border-box;
			display: flex;

			border-left-width: 96px;
			border-right-width: 96px;

			&.file-type--pdf {
				border-top-width: 0;
				border-bottom-width: 0;
			}

			.carousel-child--drop-zone-layer {
				@include pos(a, 0, auto, auto, 0);
				@include size(f);
				z-index: 1000;
				border: 2px dashed transparent;
				border-radius: 10px;

				&.is-drop-zone-active {
					border-color: red;
					cursor: crosshair;
				}
			}

			.ghost-loading-pinup {
				z-index: 1001;
				@include size(30px);
				border-radius: 50%;
				background-color: aqua;
				position: absolute;
				display: flex;
				align-items: center;
				justify-content: center;
				transform: translate(-50%, -50%);
			}

			&.is-active {
				opacity: 1;
				position: relative;
				z-index: 200;
			}

			.carousel-child--content {
				display: flex;
				flex: 1 1 auto;
				flex-direction: column;
				justify-content: center;
				min-width: 1px;
				.carousel-child--attachment-container {
					box-sizing: border-box;
					display: flex;
					flex: 1 1 auto;
					flex-direction: column;
					justify-content: center;
					min-height: 1px;
					position: relative;
					.carousel-child--attachment-file {
						align-items: center;
						box-sizing: border-box;
						display: flex;
						flex-direction: column;
						justify-content: center;
						position: relative;
						@include size(f);

						&.file-type--doc {
							justify-content: flex-start;
						}

						img {
							flex: 0 1 auto;
							image-orientation: none;
							object-fit: contain;
							object-position: 50% 50%;
							@include size(f);
						}
					}
				}
			}
		}

		.carousel-control- {
			&left,
			&right {
				align-items: center;
				cursor: pointer;
				display: flex;
				flex-direction: column;
				height: 100%;
				justify-content: center;
				opacity: 0;
				position: absolute;
				top: 0;
				transition: opacity 0.2s;
				z-index: 300;
			}
			&right {
				right: 32px;
				.carousel-control-btn svg {
					transform: rotate(-90deg);
				}
			}
			&left {
				left: 32px;
				.carousel-control-btn svg {
					transform: rotate(90deg);
				}
			}
		}

		&.has-controls-visible .carousel-control- {
			&left,
			&right {
				opacity: 1;
			}
		}

		.carousel-control-btn {
			min-height: 36px;
			min-width: 36px;
			@include size(36px);
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: transparent;
			border-radius: 5px;
			transition: background-color ease-in-out 300ms;
			&:hover {
				background-color: rgba(0, 0, 0, 0.15);
			}
		}

		&,
		&.has-horizontal-style {
			@include max-screen(600px) {
				@include size(100vw, auto);
				& > .carousel-child {
					width: 100vw;
					border-left-width: 0;
					border-right-width: 0;
					border-top-width: 0;
					border-bottom-width: 60px;
				}

				.carousel-control- {
					&left,
					&right {
						@include pos(a, auto, 10px, 10px, auto);
						height: auto;
						opacity: 1;
					}
					&left {
						right: 60px;
					}
				}
			}
		}
	}

	&.is-panel-open {
		width: calc(100vw - 600px);
	}

	&,
	&.has-horizontal-style {
		@include max-screen(600px) {
			width: 100vw !important;
		}
	}
}
</style>
