<template>
	<div class="word-md-file--component-wrapper">
		<VueDocPreview v-if="!isDocViewerNotAvalable" :id="containerId" :value="src" :type="rendType" />

		<b-container v-else class="fallback-container" style="padding-top: 200px">
			<b-alert variant="info" class="push_b_40" show>
				{{ FormMSG(1, "You've been redirected automatically") }}
			</b-alert>
			<b-button block variant="primary" @click="openInBrowser">
				{{ FormMSG(2, 'Open in browser') }}
			</b-button>
			<b-button block variant="success" @click="downloadFile">
				{{ FormMSG(3, 'Download') }}
			</b-button>
		</b-container>
	</div>
</template>

<script>
import { Browser } from '@capacitor/browser';
import VueDocPreview from 'vue-doc-preview';
import mapProps from '@/shared/vuePropsMapper';
import { isNil, makeID } from '@/shared/utils';
import { store } from '@/store';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'WordMDDocViewerComponent',
	components: { VueDocPreview },
	mixins: [languageMessages, globalMixin],
	props: mapProps(['src', 'xid', 'ext'], {
		type: String,
		required: true,
		default: null
	}),
	data() {
		return {
			containerId: `word-md-doc-view__${makeID(10)}`
		};
	},
	computed: {
		/**
		 * @return {Boolean}
		 */
		isDocViewerNotAvalable() {
			return this.$isPwa() || this.$isResponsive();
		},

		/**
		 * @return {String}
		 */
		getCurLangStr() {
			return getLangISOByIndex(store.appLanguage()).iso;
		},

		/**
		 * @return {String}
		 */
		rendType() {
			if (isNil(this.ext)) return 'txt';
			switch (this.ext) {
				case 'docx':
				case 'pptx':
				case 'xlsx':
					return 'office';
				case 'txt':
					return 'text';
				default:
					return 'txt';
			}
		},

		/**
		 * @return {String|Url}
		 */
		rendOfficeLiveUrl() {
			/** @type {String} */
			const _ = encodeURIComponent(this.src);
			return `https://view.officeapps.live.com/op/view.aspx?src=${_}`;
		}
	},
	created() {
		this.initResponsiveDocNavigation();
	},
	methods: {
		initResponsiveDocNavigation() {
			if (!this.isDocViewerNotAvalable) return;
			this.openInBrowser();
		},
		async openInBrowser() {
			await Browser.open({ url: this.rendOfficeLiveUrl });
		},
		async downloadFile() {
			await this.downloadUrl(this.src);
		}
	}
};
</script>

<style scoped>
.word-md-file--component-wrapper {
	width: 100%;
	height: 100%;
	position: relative;
}
</style>
