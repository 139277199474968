<style>
.project-toggler .dropdown-menu {
	margin: 0;
	padding: 0;
}

.project-toggler .label-only {
	color: #007949;
	font-weight: bold;
	display: inline-block;
	background-color: #eee;
	border: 1px solid #eee;
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
	line-height: 1.5;
	border-radius: 0.2rem;
}
</style>

<template>
	<div class="project-toggler dropdown" v-if="myProjects && myProjects.length > 1">
		<!-- {{ $screen.height }} -->
		<b-dropdown variant="outline-secondary" size="sm" :text="myProject ? myProject.titre : ''">
			<b-dropdown-header id="dropdown-header-label">
				{{ this.FormMSG(1, 'Switch to another project') }}
			</b-dropdown-header>
			<b-dropdown-item-button
				v-for="project in myProjects"
				:key="project.id"
				action
				v-on:click="changeProject(project)"
				href="#"
				v-bind:active="myProject && myProject.id === project.id"
				>{{ project.titre }}</b-dropdown-item-button
			>
		</b-dropdown>
	</div>
	<div class="project-toggler" v-else>
		<span class="label-only">{{ myProject ? myProject.titre : '' }}</span>
	</div>
</template>

<script>
import gql from 'graphql-tag';
import { store } from '@/store';
import { myContracts } from '@/cruds/contract.crud';
import languageMessages from '@/mixins/languageMessages';
import { getParamVersion } from '@/shared/version';
import { mapActions } from 'vuex';

const query_myProjects = gql`
	query {
		myProjects {
			id
			titre
			production
		}
	}
`;

const query_myProject = gql`
	query {
		myProject {
			id
			titre
			production
			realisateur
		}
	}
`;

// Variable doit être dans la query : https://github.com/graphql/graphiql/issues/156
const mutation_changeProject = gql`
	mutation ($id: ID!) {
		changeProject(projectID: $id)
	}
`;

export default {
	name: 'HeaderProjectToggler',
	mixins: [languageMessages],
	apollo: {
		myProjects: {
			query: query_myProjects,
			fetchPolicy: 'no-cache',
			error(err) {
				this.erreur = err;
			}
		},
		myProject: {
			query: query_myProject,
			error(err) {
				this.erreur = err;
			},
			fetchPolicy: 'no-cache'
		}
	},
	data: function () {
		return {
			// Initialize your apollo data
			erreur: {},
			myerr: '',
			shared: store.state
		};
	},

	methods: {
		...mapActions({
			getNotifications: 'header/getNotifications',
			initFilterActive: 'global-filter/initFilterActive',
			getDocuments: 'document/getDocuments'
		}),
		async changeProject(project) {
			// position on dashboar screen first
			// this.$router.push("/")
			const id = parseInt(project.id, 10);
			await this.$apollo
				.mutate({
					mutation: mutation_changeProject,
					variables: {
						id
					}
				})
				.then(async (result) => {
					await store.onNewToken(this.$apollo.provider.defaultClient, result.data.changeProject); // executer onNewToken avant de storer le nouveau projet, sinon supprimé
					//console.log("IN CHANGE PROJECT",result.data.changeProject)
					store.setProjectAction(project); // à compléter par la suite pour réinitialiser les variables du store lors du changement de projet
					//  this.$router.push("home");
					this.initFilterActive();
					const tmp = await myContracts();
					// console.log("in myContract DefaultHeaderDropdownAccnt tmp:",tmp);
					store.state.myContracts = tmp;
					this.myerr = '';
					this.$router.push('/my-dashboard' + getParamVersion()).catch(() => {});
					await this.getNotifications(project.id);
					this.getDocuments({
						userId: +store.userID()
					});
				})
				.catch((error) => {
					this.erreur = error;
				});
		}
	}
};
</script>
<style scoped>
::v-deep .dropdown-menu {
	max-height: 650px;
	overflow-y: auto;
}
</style>
