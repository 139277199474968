var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "images-list-thumbnails--imagesshowroom--component-wrapper",
      class: _vm.rendImagesListThumbnails,
    },
    _vm._l(_vm.imagesList, function (item, i) {
      return _c(
        "div",
        {
          key: i,
          staticClass: "thumbnail-image",
          class: { "is-active": _vm.isActive(item.xid) },
          on: {
            click: function ($event) {
              return _vm.handleThumbnailClick(item.xid)
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "lazy-container",
                  rawName: "v-lazy-container",
                  value: { selector: "img" },
                  expression: "{ selector: 'img' }",
                },
              ],
              staticClass: "img-lazy-container",
            },
            [
              _c("img", {
                staticClass: "w-100 h-auto",
                attrs: {
                  "data-src": _vm.rendItemBackgroundImage(item),
                  "data-error": "img/thumb-not-found.png",
                  "data-loading": "img/brand/tenor.gif",
                },
              }),
            ]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }