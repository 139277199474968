var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.myProjects && _vm.myProjects.length > 1
    ? _c(
        "div",
        { staticClass: "project-toggler dropdown" },
        [
          _c(
            "b-dropdown",
            {
              attrs: {
                variant: "outline-secondary",
                size: "sm",
                text: _vm.myProject ? _vm.myProject.titre : "",
              },
            },
            [
              _c(
                "b-dropdown-header",
                { attrs: { id: "dropdown-header-label" } },
                [
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(this.FormMSG(1, "Switch to another project")) +
                      "\n\t\t"
                  ),
                ]
              ),
              _vm._l(_vm.myProjects, function (project) {
                return _c(
                  "b-dropdown-item-button",
                  {
                    key: project.id,
                    attrs: {
                      action: "",
                      href: "#",
                      active: _vm.myProject && _vm.myProject.id === project.id,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeProject(project)
                      },
                    },
                  },
                  [_vm._v(_vm._s(project.titre))]
                )
              }),
            ],
            2
          ),
        ],
        1
      )
    : _c("div", { staticClass: "project-toggler" }, [
        _c("span", { staticClass: "label-only" }, [
          _vm._v(_vm._s(_vm.myProject ? _vm.myProject.titre : "")),
        ]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }